import React, { useState } from "react";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import Headline from "../Headline/Headline";
import { useAPI } from "../../../apiContext";

const Galleryphoto = ({ featureActiveBtn, findFeature }) => {
	const {
		loading,
		setLoading,
		databyEmail,
		userMediaMemory,
		findFeature: find,
		token,
	} = useAPI();

	const [open, setOpen] = useState(false);
	const [image, setImage] = useState(null);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file && file.size > 1.5 * 1024 * 1024) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "File size is too big! Max file size is 1.5MB",
			});
		} else {
			setImage(file);
		}
	};
	const handleSave = async () => {
		const data = new FormData();
		data.append("file", image);
		data.append("upload_preset", "DigiCardsPro-Gallery");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/freedomvirtualsolutions/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		const uploadData = {
			type: "Gallery",
			galleryId: `gallery${Math.floor(100000 + Math.random() * 900000)}`,
			imageLink2: file?.secure_url,
			pre_public_id: file?.public_id,
			bytes: file?.bytes,
			title: inputs?.title || "",
			buttonText: inputs?.buttonText || "",
			buttonLink: inputs?.buttonLink || "",
			submitTime: new Date().toLocaleString(),
		};
		if (await file?.secure_url) {
			try {
				await axios
					.put(
						`${process.env.REACT_APP_API_PATH}/gallery/${databyEmail?._id}`,
						uploadData,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					)
					.then(function (response) {
						Swal.fire({
							icon: "success",
							title: "Your photo uploaded Successfully",
							showConfirmButton: false,
							timer: 1500,
						});
						setLoading(false);
						setOpen(false);
						setInputs({});
						setImage(null);
					});
			} catch (error) {
				if (error.response.status) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data,
					});
				} else {
					console.log(error);
				}
			}
		}
	};

	const handleCancel = () => {
		setImage(null);
		setOpen(false);
	};
	const [inputs, setInputs] = useState({
		title: "",
		buttonText: "",
		buttonLink: "",
	});

	const [selectedPhoto, setSelectedPhoto] = useState(null);

	const handlePhotoClick = (photo) => {
		setSelectedPhoto(photo);
	};

	const handleDeleteClick = (photo) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				try {
					await axios
						.put(
							`${process.env.REACT_APP_API_PATH}/deleteGallery/${databyEmail?._id}/${photo?.galleryId}`,
							{
								pre_public_id: photo?.pre_public_id,
							},
							{
								headers: {
									Authorization: `Bearer ${token}`,
								},
							},
						)
						.then(function (response) {
							setLoading(false);
							Swal.fire(
								"Deleted!",
								"That gallery image has been deleted.",
								"success",
							);
						});
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			}
		});
	};

	const handleCloseClick = () => {
		setSelectedPhoto(null);
	};
	return (
		<Box
			sx={{
				my: 2,
				position: "relative",
				border:
					(window.location.pathname.includes("/dashboard/galleryphoto")
						? find("Gallery")
						: findFeature("Gallery")
					)?.status !== true && "3px solid red !important",
				backgroundColor:
					(window.location.pathname.includes("/dashboard/galleryphoto")
						? find("Gallery")
						: findFeature("Gallery")
					)?.status !== true && "#ff000038 !important",
			}}>
			{!window.location.pathname.includes("/dashboard/galleryphoto") && (
				<Headline type={"gallery"} />
			)}
			<Box
				style={{
					padding: 15,
					margin: 0,
					borderRadius: 7,
				}}>
				<Button variant='outlined' onClick={handleOpen} sx={{ mb: 2 }}>
					Upload New Item
				</Button>
				<Box sx={{ maxHeight: "80vh", overflowY: "scroll" }}>
					{" "}
					{databyEmail?.gallery?.length > 0 ? (
						<Grid container spacing={2}>
							{databyEmail?.gallery?.map((photo, key) => (
								<Grid
									key={key}
									item
									xs={
										window.location.pathname.includes("/dashboard/galleryphoto")
											? 4
											: 6
									}>
									<Box sx={{ position: "relative" }}>
										<img
											src={photo.imageLink2}
											alt={photo.title}
											onClick={() => handlePhotoClick(photo)}
											style={{ width: "100%", borderRadius: "8px" }}
										/>
										<Box
											sx={{
												position: "absolute",
												bottom: 0,
												left: 0,
												right: 0,
												px: 1,
												backgroundColor: "rgba(0, 0, 0, 0.5)",
												color: "white",
												display: "flex",
												justifyContent: "space-between",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Typography>{photo?.title?.slice(0, 10)}....</Typography>

											<Box sx={{ flex: "0 0 auto" }}>
												<Button
													variant='outlined'
													size='small'
													sx={{ mr: 1 }}
													onClick={() =>
														window.open(photo?.buttonLink, "_blank")
													}>
													{photo?.buttonText}
												</Button>
												<IconButton
													onClick={() => handleDeleteClick(photo)}
													sx={{ color: "white" }}>
													<DeleteIcon />
												</IconButton>
											</Box>
										</Box>
									</Box>
								</Grid>
							))}
						</Grid>
					) : (
						<Typography
							className='textColor'
							sx={{ mb: 0.5, fontWeight: 900 }}
							variant='h6'
							component='div'
							gutterBottom>
							No Images !...
						</Typography>
					)}
				</Box>
			</Box>{" "}
			{selectedPhoto && (
				<Box
					sx={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}>
					<Box sx={{ position: "relative" }}>
						<img
							src={selectedPhoto.imageLink2}
							alt={selectedPhoto.title}
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
								borderRadius: "8px",
							}}
						/>
						<Box sx={{ position: "absolute", top: 0, right: 0, p: 1 }}>
							<IconButton onClick={handleCloseClick} sx={{ color: "white" }}>
								<CancelIcon />
							</IconButton>
						</Box>
					</Box>
				</Box>
			)}
			<Dialog open={open} onClose={handleClose}>
				{parseFloat(userMediaMemory?.mediaUsed) <
				parseFloat(userMediaMemory?.mediaLimit) ? (
					<>
						{!loading ? (
							<>
								<DialogTitle>Select Image</DialogTitle>
								<DialogContent>
									{image ? (
										<img
											src={URL.createObjectURL(image)}
											alt='selected'
											style={{ width: "100%", maxHeight: "450px" }}
										/>
									) : (
										<Typography>No image selected</Typography>
									)}
									<input
										type='file'
										accept='image/*'
										onChange={handleImageChange}
										sx={{ mt: 2 }}
									/>
									<br />
									{image && (
										<Box
											sx={{
												my: 2,
												textAlign: "left",
												"& table tr td": {
													p: 0.3,
												},
											}}>
											<table>
												<tr>
													<td>Title : </td>
													<td>
														<input
															type='text'
															value={inputs.title}
															onChange={(e) =>
																setInputs({ ...inputs, title: e.target.value })
															}
															placeholder='Title'
														/>
													</td>
												</tr>
												<tr>
													<td>Button Text : </td>
													<td>
														<input
															type='text'
															value={inputs.buttonText}
															onChange={(e) =>
																setInputs({
																	...inputs,
																	buttonText: e.target.value,
																})
															}
															placeholder='Button Text'
														/>
													</td>
												</tr>
												<tr>
													<td>Button Link : </td>
													<td>
														<input
															type='text'
															value={inputs.buttonLink}
															onChange={(e) =>
																setInputs({
																	...inputs,
																	buttonLink: e.target.value,
																})
															}
															placeholder='Button Link'
														/>
													</td>
												</tr>
											</table>
										</Box>
									)}
								</DialogContent>
								<DialogActions
									sx={{ display: "flex", justifyContent: "space-between" }}>
									<Button onClick={handleCancel}>Cancel</Button>
									{image && (
										<div>
											<IconButton onClick={() => setImage(null)} sx={{ mr: 1 }}>
												<CancelIcon />
											</IconButton>
											<IconButton onClick={handleSave}>
												<SaveAltIcon />
											</IconButton>
										</div>
									)}
								</DialogActions>
							</>
						) : (
							<Backdrop
								open={open}
								sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
								<CircularProgress
									color='primary'
									size={60}
									sx={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
							</Backdrop>
						)}
					</>
				) : (
					<Typography sx={{ p: 2 }}>
						<span style={{ color: "red" }}>
							You have reached your media limit. Please upgrade your plan to
							upload more images.
						</span>
						<br /> Memory Used : {parseFloat(userMediaMemory?.mediaUsed)} MB
						<br />
						Memory Plan : {parseFloat(userMediaMemory?.mediaLimit)} MB
					</Typography>
				)}
			</Dialog>
		</Box>
	);
};

export default Galleryphoto;
