import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AllMails from "./Pages/Dashboard/AllMails/AllMails";
import AllReviews from "./Pages/Dashboard/AllReviews/AllReviews";
import BannerPhoto from "./Pages/Dashboard/BannerPhoto/BannerPhoto";
import ButtonsLink from "./Pages/Dashboard/ButtonsLink/ButtonsLink";
import ColorChange from "./Pages/Dashboard/ColorChange/ColorChange";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Details from "./Pages/Dashboard/Details/Details";
import Galleryphoto from "./Pages/Dashboard/Galleryphoto/Galleryphoto";
import Headline from "./Pages/Dashboard/Headline/Headline";
import ProfilePhoto from "./Pages/Dashboard/ProfilePhoto/ProfilePhoto";
import HomepageMain from "./Pages/Homepage/HomepageMain";
import Login from "./Pages/Login/Login";
import NotFound from "./Pages/NotFound/NotFound";
import RequireAuth from "./RequireAuth/RequireAuth";
import { Typography } from "@mui/material";
import AddVideo from "./Pages/Dashboard/AddVideo/AddVideo";
import { APIContextProvider } from "./apiContext";
import CreateAcc from "./CreateAcc/CreateAcc";
import AllUsers from "./Pages/Dashboard/AllUsers/AllUsers";
import MyProfile from "./Pages/Dashboard/MyProfile/MyProfile";
import RequireAuthAdmin from "./RequireAuth/RequireAuthAdmin";
import InActiveCard from "./Pages/Dashboard/InActiveCard/InActiveCard";
import ShareManage from "./Pages/Dashboard/ShareManage/ShareManage";
import AllSupports from "./Pages/Dashboard/AllSupports/AllSupports";
import Test from "./Test";
import QrScanRedirect from "./Pages/QrScanRedirect/QrScanRedirect";
import Statistics from "./Pages/Dashboard/Statistics/Statistics";
import BeforeInstallSubmits from "./Pages/Dashboard/BeforeInstallSubmits/BeforeInstallSubmits";
import PaymentSuccess from "./Pages/Dashboard/Payment/PaymentSuccess";
import Payment from "./Pages/Dashboard/Payment/Payment";
import ManagePackage from "./Pages/Dashboard/ManagePackage/ManagePackage";
import Refferels from "./Pages/Dashboard/Refferels/Refferels";
import UseData from "./useData";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import Terms from "./Pages/Dashboard/Terms/Terms";
import ChatBot from "./Pages/Dashboard/ChatBot/ChatBot";
import { Helmet } from "react-helmet";

function App() {
	const { data } = UseData();
	const url = window.location.pathname;
	/* 	useEffect(() => {
		if (data) {
			const profileImage = data.profile?.imageLink2?.split("/upload");
			const bannerImage = data.banner?.imageLink2?.split("/upload");
			const title = document.getElementById("title");

			if (title) {
				title.innerText = data.headline?.title;
			}

			const MetaTitle = document.getElementsByName("title");
			MetaTitle.forEach((element) => {
				element.content = data.headline?.title;
			});

			const MetaDescription = document.getElementsByName("description");
			MetaDescription.forEach((element) => {
				element.content = data.details?.details;
			});

			const icon = document.getElementsByName("icon");
			if (profileImage && profileImage.length === 2) {
				icon.forEach((element) => {
					element.href =
						profileImage[0] + `/upload/h_400,w_400` + profileImage[1];
				});
			}

			const meataIcon = document.getElementById("icon");
			if (profileImage && profileImage.length === 2 && meataIcon) {
				meataIcon.content =
					profileImage[0] + `/upload/h_400,w_400` + profileImage[1];
			}

			const banner = document.getElementsByName("banner");
			if (bannerImage && bannerImage.length === 2) {
				banner.forEach((element) => {
					element.content =
						bannerImage[0] + `/upload/h_630,w_1200` + bannerImage[1];
				});
			}
		}
	}, [data]); */

	return (
		<div
			className='App'
			style={{
				backgroundColor: `${data?.color?.color3rd}`,
			}}>
			<Helmet>
				{data?.url && !url.includes("/dashboard") && (
					<link
						rel='manifest'
						href={`${process.env.REACT_APP_API_PATH}/manifest/${
							url?.includes("/@") ? url?.split("/@")[1] : data?.url
						}`}
					/>
				)}
			</Helmet>
			{data ? (
				<>
					{document.documentElement.style.setProperty(
						"--color1st",
						`${data?.color?.color1st}`,
					)}
					{document.documentElement.style.setProperty(
						"--color2nd",
						`${data?.color?.color2nd}`,
					)}
					{document.documentElement.style.setProperty(
						"--hr",
						`${data?.color?.hr}`,
					)}
					{data?._id ? (
						<>
							<APIContextProvider>
								<BrowserRouter>
									<Routes>
										<Route path='*' element={<NotFound />} />
										<Route path='/test' element={<Test />} />
										{url === "/" && (
											<Route
												path='/'
												element={<HomepageMain url={`/`} data={data} />}
											/>
										)}
										{url.includes("/@") && (
											<Route
												path={`/@${data?.url}`}
												element={
													<HomepageMain url={`/@${data?.url}`} data={data} />
												}
											/>
										)}
										<Route
											path={`/QrScan-:user`}
											element={<QrScanRedirect data={data} />}
										/>
										<Route path='/login' element={<Login />} />
										{!url.includes("/redirectedFrom") ? (
											<Route path='/createAccount' element={<CreateAcc />} />
										) : (
											<Route
												path={`/createAccount/redirectedFrom=:id`}
												element={<CreateAcc />}
											/>
										)}
										<Route
											exact
											path='/dashboard'
											element={
												<RequireAuth>
													<Dashboard url={window?.location.origin.toString()} />
												</RequireAuth>
											}>
											<Route path='/dashboard/Payment' element={<Payment />} />
											<Route
												path='/dashboard/paymentSuccess'
												element={<PaymentSuccess />}
											/>
											<Route
												path='/dashboard/Refferels'
												element={<Refferels />}
											/>
											<Route
												path='/dashboard/Statistics'
												element={<Statistics />}
											/>
											<Route path='/dashboard' element={<MyProfile />} />
											<Route
												path='/dashboard/allusers'
												element={
													<RequireAuthAdmin>
														<AllUsers />
													</RequireAuthAdmin>
												}
											/>
											<Route
												path='/dashboard/ManagePackage'
												element={
													<RequireAuthAdmin>
														<ManagePackage />
													</RequireAuthAdmin>
												}
											/>
											<Route
												path='/dashboard/AllSupports'
												element={
													<RequireAuthAdmin>
														<AllSupports />
													</RequireAuthAdmin>
												}
											/>
											<Route
												path='/dashboard/Terms'
												element={
													<RequireAuthAdmin>
														<Terms />
													</RequireAuthAdmin>
												}
											/>
											<Route
												path='/dashboard/bannerphoto'
												element={<BannerPhoto />}
											/>
											<Route
												path='/dashboard/profilephoto'
												element={<ProfilePhoto />}
											/>

											<Route
												path='/dashboard/headline'
												element={<Headline />}
											/>
											<Route
												path='/dashboard/InActiveCard'
												element={<InActiveCard />}
											/>
											<Route
												path='/dashboard/ShareManage'
												element={<ShareManage />}
											/>

											<Route
												path='/dashboard/buttonslink'
												element={<ButtonsLink />}
											/>

											<Route
												path='/dashboard/colorchange'
												element={<ColorChange />}
											/>
											<Route
												path='/dashboard/BeforeInstallSubmits'
												element={<BeforeInstallSubmits />}
											/>
											<Route path='/dashboard/details' element={<Details />} />
											<Route path='/dashboard/ChatBot' element={<ChatBot />} />

											<Route
												path='/dashboard/galleryphoto'
												element={<Galleryphoto />}
											/>

											{/* 	<Route path='/dashboard/bg' element={<Bg />} /> */}

											<Route
												path='/dashboard/allreviews'
												element={<AllReviews />}
											/>
											<Route
												path='/dashboard/allemails'
												element={<AllMails />}
											/>

											<Route
												path='/dashboard/managevideo'
												element={<AddVideo />}
											/>
										</Route>
									</Routes>
								</BrowserRouter>
							</APIContextProvider>
						</>
					) : (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								minHeight: "100vh",
							}}>
							{" "}
							<NoAccountsIcon
								className='textColor'
								sx={{
									fontSize: "9rem",
									marginBottom: "1rem",
								}}
							/>
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h3'
								component='div'>
								OPPS!
							</Typography>
							<br />
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h5'
								component='div'>
								{data}
							</Typography>
						</div>
					)}
				</>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<span className='loader'></span>
					<span className='loader2'>Loading</span>
				</div>
			)}
		</div>
	);
}

export default App;
