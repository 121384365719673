import {
	Backdrop,
	Button,
	Card,
	CircularProgress,
	Container,
	Fade,
	Modal,
	Typography,
} from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Swal from "sweetalert2";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	maxWidth: "95vw",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const Support = ({ open, setOpen, support, setDeleted, token }) => {
	return (
		<Container>
			{support ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style}>
							<Box>
								<Card
									className='borderColor'
									sx={{
										p: 2,
										my: 1,
										minWidth: "300px",
										display: "flex",
										flexDirection: "column",
										alignContent: "center",
										overflow: "visible",
										border: "2px solid ",
										textAlign: "left",
										borderRadius: 3,
									}}>
									<CancelIcon
										onClick={() => setOpen(false)}
										className='textColor'
										sx={{
											position: "fixed",
											top: "13px",
											right: "5px",
											cursor: "pointer",
										}}
									/>

									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'>
										<b>Name : </b>
										<a
											className='textColor'
											href={`${window.location.origin.toString()}/@${
												support?.userId
											}`}
											target='blank'>
											{support?.userId}
										</a>
									</Typography>

									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'>
										<b>Rec Time : </b>
										{support?.submitTime}
									</Typography>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'>
										<b>Subject : </b>
										{support?.subject}
									</Typography>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'>
										<b>Problem : </b>
										{support?.why}
									</Typography>
									{support?.solved === true ? (
										<Button
											variant='contained'
											sx={{
												px: 3,
												fontWeight: "bold",
												borderRadius: "5px",
												backgroundColor: "green !important",
												pointerEvents: "none",
											}}>
											Solved
										</Button>
									) : (
										<Button
											onClick={async () => {
												setDeleted(false);
												try {
													await axios
														.put(
															`${process.env.REACT_APP_API_PATH}/support/${support?._id}`,
															{
																status: true,
															},
															{
																headers: {
																	Authorization: `Bearer ${token}`,
																},
															},
														)
														.then(function (response) {
															Swal.fire({
																icon: "success",
																title: "Successfully Solved",
																showConfirmButton: false,
																timer: 1500,
															});
															setDeleted(false);
															setOpen(false);
														});
												} catch (error) {
													if (error.response.status) {
														Swal.fire({
															icon: "error",
															title: "Oops...",
															text: error.response.data,
														});
													} else {
														console.log(error);
													}
												}
											}}
											variant='contained'
											sx={{
												px: 3,
												fontWeight: "bold",
												borderRadius: "5px",
												backgroundColor: "red !important",
											}}>
											Mark as Solved
										</Button>
									)}
								</Card>
							</Box>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</Container>
	);
};

export default Support;
