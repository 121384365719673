import React from "react";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { useAPI } from "../../../../apiContext";

const CopyRight = () => {
	const { selecetedData } = useAPI();
	return (
		<>
			{selecetedData && (
				<div
					style={{
						backgroundColor: "rgb(0 0 0 / 5%)",
						padding: "15px",
					}}
					className='textColor'>
					<Divider className='borderColor' />
					<Typography variant='body1' component='div' sx={{ py: 1 }}>
						<span
							onClick={() =>
								window.open(
									`/createAccount/redirectedFrom=${selecetedData?.url}`,
									"_blank",
								)
							}
							style={{
								fontWeight: "bold",
								cursor: "pointer",
								textDecoration: "underline",
							}}
							className='textColor'>
							Get your own Digital Business Card App
						</span>
						<br />
						Copyright © {new Date()?.getFullYear()} Freedom Virtual Solutions,
						LLC – All rights reserved.
					</Typography>
				</div>
			)}
		</>
	);
};

export default CopyRight;
