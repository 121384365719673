import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Stripe from "stripe";
import axios from "axios";
import { useAPI } from "../../../apiContext";
import Swal from "sweetalert2";
import Confetti from "react-confetti";

const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRECT_KEY);

const PaymentSuccess = () => {
	const { databyEmail, token } = useAPI();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const sessionId = searchParams.get("sessionId");
	const [customerId, setCustomerId] = useState();
	const [subscriptionDetails, setSubscriptionDetails] = useState();
	const [paymentActive, setPaymentActive] = useState();

	useEffect(() => {
		if (sessionId) {
			const retrieveSessionInfo = async (sessionId) => {
				try {
					const session = await stripe.checkout.sessions.retrieve(sessionId);

					// Log or process the session information
					setCustomerId(session?.customer);
				} catch (error) {
					console.error("Error retrieving session information:", error);
				}
			};

			retrieveSessionInfo(sessionId);
		}
	}, [sessionId]);

	useEffect(() => {
		if (customerId) {
			const getSubscriptionDetails = async (customerId) => {
				try {
					const subscriptions = await stripe.subscriptions.list({
						customer: customerId,
					});

					setSubscriptionDetails({
						currentPeriodStart: new Date(
							subscriptions.data[0].current_period_start * 1000,
						).toLocaleString(),
						currentPeriodEnd: new Date(
							subscriptions.data[0].current_period_end * 1000,
						).toLocaleString(),
						status: subscriptions.data[0].status,
					});
				} catch (error) {
					console.error("Error retrieving subscription details:", error);
				}
			};

			getSubscriptionDetails(customerId);
		}
	}, [customerId]);

	useEffect(() => {
		if (databyEmail?.url && subscriptionDetails) {
			const fetchData = async () => {
				try {
					const paymentInfos = await axios.post(
						`${process.env.REACT_APP_API_PATH}/subscriptionActive/${databyEmail?.url}`,
						{
							startTime: subscriptionDetails?.currentPeriodStart,
							endTime: subscriptionDetails?.currentPeriodEnd,
							subscriptionStatus: subscriptionDetails?.status,
							customerId: customerId,
						},
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);

					setPaymentActive(paymentInfos?.data);
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			};

			if (token) {
				fetchData();
			}
		}
	}, [databyEmail?.url, subscriptionDetails, customerId, token]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
			}}>
			{subscriptionDetails &&
			subscriptionDetails?.status === "trialing" &&
			paymentActive?.status ? (
				<>
					<Box
						className='borderColor'
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							border: "2px solid",
							borderRadius: "8px",
							p: 3,
							width: "auto",
							maxWidth: "400px",
							textAlign: "center",
							boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
						}}>
						<Typography variant='h4' className='textColor'>
							Payment Successful!
						</Typography>
						<Typography variant='h6' sx={{ mb: 2 }} className='textColor'>
							Your subscription is now active.
						</Typography>
						<Confetti
							style={{
								width: "100vw",
								height: "100vh",
								zIndex: 9999,
							}}
						/>
						<Typography variant='body1' sx={{ mb: 2 }}>
							Thank you for your payment. Your subscription is now active. You
							can now start using all the premium features.
						</Typography>
						<Button
							sx={{ border: "1px solid", mt: 2 }}
							variant='contained'
							color='primary'
							onClick={() => (window.location.href = "/dashboard")}>
							Return to Dashboard
						</Button>
					</Box>
				</>
			) : (
				<>
					<Typography variant='h4' sx={{ mb: 4 }} className='textColor'>
						Do not refresh the page. Redirecting...
					</Typography>
					<Box sx={{ display: "flex" }}>
						<CircularProgress className='textColor' />
					</Box>
				</>
			)}
		</Box>
	);
};

export default PaymentSuccess;
