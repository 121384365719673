import { Box, Container, Divider, Typography } from "@mui/material";
import React from "react";
import { useAPI } from "../../../../apiContext";

const Map = () => {
	const { selecetedData: data, findFeature } = useAPI();

	return (
		<>
			{findFeature("Map")?.status === true && (
				<Container sx={{ pt: 3 }}>
					<Box
						sx={{
							border: "3px solid",
							borderRadius: "15px",
							p: 1,
							"& .map iframe": {
								width: "100% !important",
								minHeight: "100% !important",
								height: { md: "70vh", xs: "50vh" },
								borderRadius: "9px !important",
							},
						}}
						className='borderColor'>
						<Typography
							className='textColor'
							sx={{ mb: 0.5 }}
							variant='h4'
							component='div'
							gutterBottom>
							{data?.headline?.findUs}
							<Divider className={`${data?.color?.hr}`} />
						</Typography>
						<Box>
							<div
								className='map'
								dangerouslySetInnerHTML={{ __html: data?.map?.map }}
							/>
						</Box>
					</Box>
				</Container>
			)}
		</>
	);
};

export default Map;
