import { Box, Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Mail from "./Mail";
import Headline from "../Headline/Headline";
import { useAPI } from "../../../apiContext";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AllMails = ({ featureActiveBtn, findFeature }) => {
	const [open, setOpen] = React.useState(false);
	const [view, setView] = useState();
	const handleOpen = (viewMail) => {
		setView(viewMail);
		setOpen(true);
	};

	const { setLoading, databyEmail, findFeature: find, token } = useAPI();

	const handleDelete = (deletedMailId) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				try {
					await axios
						.put(
							`${process.env.REACT_APP_API_PATH}/deleteMail/${databyEmail?._id}/${deletedMailId}`,
							{},
							{
								headers: {
									Authorization: `Bearer ${token}`,
								},
							},
						)
						.then(function (response) {
							Swal.fire("Deleted!", "That mail has been deleted.", "success");
							setLoading(false);
						});
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			}
		});
	};

	const columns = [
		{
			field: "userName",
			headerName: "Sender",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "submitTime",
			headerName: "Sent Time",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "userEmail",
			headerName: "Sender Email",
			minWidth: 220,
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 310,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Button
							onClick={() => handleOpen(params.row)}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							className='button border'
							onClick={() => handleDelete(params.row?.mailId)}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<DeleteIcon />
						</Button>
					</>
				);
			},
		},
	];
	return (
		<Box
			sx={{
				my: 2,
				position: "relative",
				border:
					(window.location.pathname.includes("/dashboard/allemails")
						? find("Mails")
						: findFeature("Mails")
					)?.status !== true && "3px solid red !important",
				backgroundColor:
					(window.location.pathname.includes("/dashboard/allemails")
						? find("Mails")
						: findFeature("Mails")
					)?.status !== true && "#ff000038 !important",
			}}>
			{!window.location.pathname.includes("/dashboard/allemails") && (
				<Headline type={"contact"} />
			)}

			<Box
				style={{
					padding: 15,
					margin: 0,
					borderRadius: 7,
				}}>
				{databyEmail && (
					<Box
						sx={{
							width: "100%",
							height: { md: "80vh", xs: "90vh" },
							"& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
								border: "1px solid",
								fontSize: "0.75rem",
								padding: "0px 5px",
								textTransform: "capitalize",
							},
							"& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
								fontSize: "1rem",
							},
						}}>
						<DataGrid
							componentsProps={{
								panel: {
									sx: {
										"& .MuiDataGrid-panelContent": {
											maxWidth: "100vw !important",
										},
									},
								},
							}}
							rows={databyEmail?.emails || []}
							getRowId={(row) => row?.mailId}
							columns={columns}
							components={{
								Toolbar: GridToolbar,
							}}
							disableSelectionOnClick
						/>
					</Box>
				)}

				{open && <Mail open={open} setOpen={setOpen} view={view} />}
			</Box>
		</Box>
	);
};

export default AllMails;
