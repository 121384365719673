import { Typography } from "@mui/material";
import React from "react";
import WebAssetOffIcon from "@mui/icons-material/WebAssetOff";

const NotFound = () => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				minHeight: "100vh",
			}}>
			<WebAssetOffIcon
				className='textColor'
				sx={{
					fontSize: "9rem",
					marginBottom: "1rem",
				}}
			/>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h3'
				component='div'>
				OPPS!
			</Typography>
			<br />
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h5'
				component='div'>
				404, Page Not Found
			</Typography>
		</div>
	);
};

export default NotFound;
