import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(12),
		border: "2px solid red",
		boxShadow: "0px 0px 10px 0px red",
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: "red",
		width: "3em",
		height: "1.5em",
		marginTop: "-1.7em !important",
	},
}));
export default HtmlTooltip;
