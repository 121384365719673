import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPI } from "../../../apiContext";
import axios from "axios";
import Headline from "../Headline/Headline";

const ManageMap = ({ featureActiveBtn, findFeature }) => {
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			map: "",
		},
	});

	const { setLoading, databyEmail, token } = useAPI();
	useEffect(() => {
		reset(databyEmail?.map);
	}, [databyEmail, reset]);
	const onSubmit = async ({ map }) => {
		const data = {
			type: "map",
			map,
			submitTime: new Date().toLocaleString(),
		};
		setLoading(true);
		try {
			await axios
				.put(
					`${process.env.REACT_APP_API_PATH}/map/${databyEmail?._id}`,
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
				.then(function (response) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: "Updated Successfully",
						showConfirmButton: false,
						timer: 1500,
					});
				});
		} catch (error) {
			if (error.response.status) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.response.data,
				});
			} else {
				console.log(error);
			}
		}
	};

	return (
		<Box
			sx={{
				my: 2,
				position: "relative",
				border:
					findFeature("Map")?.status !== true && "3px solid red !important",
				backgroundColor:
					findFeature("Map")?.status !== true && "#ff000038 !important",
			}}
			className='manageMap'>
			<Headline type={"findUs"} />

			<form onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={2}>
					<Grid item md={12} sm={12} xs={12}>
						<TextField
							required
							name='map'
							label='Map Embed Code'
							fullWidth
							multiline
							rows={3}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("map", { required: true })}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Box
							sx={{
								"& .map iframe": {
									width: "100%",
									minHeight: "250px",
									border: "4px solid",
								},
							}}>
							<div
								className='map'
								dangerouslySetInnerHTML={{ __html: databyEmail?.map?.map }}
							/>
						</Box>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								px: 3,
								border: "1px solid",
								fontWeight: "bold",
								borderRadius: "5px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default ManageMap;
