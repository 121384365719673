import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	Box,
	Divider,
} from "@mui/material";
import React from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useAPI } from "../../../../apiContext";

const Contact = () => {
	const { selecetedData: data, setLoading, findFeature } = useAPI();
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ userName, userEmail, phoneNumber, subject, message }) => {
		const datas = {
			mailId: `mail${Math.floor(100000 + Math.random() * 900000)}`,
			userName,
			userEmail,
			phoneNumber,
			subject,
			message,
			submitTime: new Date().toLocaleString(),
			receiver: data?.email,
		};
		setLoading(true);

		axios
			.put(`${process.env.REACT_APP_API_PATH}/emails/${data?._id}`, datas)
			.then(function (response) {
				setLoading(false);
				Swal.fire({
					icon: "success",
					title: "Your Mail Sent Successfully",
					showConfirmButton: true,
					timer: 1500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<>
			{findFeature("Mails")?.status === true && (
				<Container sx={{ pt: 5, pb: 2 }}>
					<Box
						sx={{ border: "3px solid", borderRadius: "15px", p: 1 }}
						size='small'
						className='borderColor'>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Typography
								size='small'
								className='textColor'
								variant='h4'
								component='div'
								gutterBottom>
								{data?.headline?.contact}
								<Divider className={`${data?.color?.hr}`} />
							</Typography>
						</Box>
						<Typography>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
									<form onSubmit={handleSubmit(onSubmit)} method='post'>
										<Grid container spacing={2}>
											<Grid item md={6} xs={12}>
												<Grid container spacing={2}>
													<Grid item md={12} xs={12}>
														<TextField
															required
															sx={{ width: "100%" }}
															id='outlined-basic'
															size='small'
															name='UserName'
															label='Name'
															{...register("userName", { required: true })}
														/>
													</Grid>
													<Grid item md={12} xs={12}>
														<TextField
															required
															sx={{ width: "100%" }}
															id='outlined-basic'
															size='small'
															name='UserEmail'
															type='email'
															label='Email'
															{...register("userEmail", { required: true })}
														/>
													</Grid>
													<Grid item md={12} xs={12}>
														<TextField
															sx={{ width: "100%" }}
															id='outlined-basic'
															size='small'
															name='PhoneNumber'
															type='tel'
															label='Phone Number'
															{...register("phoneNumber")}
														/>
													</Grid>
													<Grid item md={12} xs={12}>
														<TextField
															required
															sx={{ width: "100%", mb: { md: 2, xs: 0 } }}
															id='outlined-basic'
															label='Subject'
															size='small'
															name='Subject'
															{...register("subject", { required: true })}
														/>
													</Grid>
												</Grid>
											</Grid>

											<Grid item md={6} xs={12}>
												<TextField
													required
													sx={{ width: "100%", mb: 2 }}
													id='"outlined-multiline-flexible'
													label='Message'
													size='small'
													name='Message'
													multiline
													rows={8.4}
													{...register("message", { required: true })}
												/>
											</Grid>
										</Grid>
										<Button
											type='submit'
											variant='contained'
											sx={{
												width: "100%",
												mb: 2,
												px: 3,
												fontWeight: "bold",
												borderRadius: "7px",
												border: "1px solid",
											}}>
											Submit
										</Button>
									</form>
								</Grid>
							</Grid>
						</Typography>
					</Box>
				</Container>
			)}
		</>
	);
};

export default Contact;
