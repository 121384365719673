import React, { useEffect, useState } from "react";
import { CircularProgress, Backdrop, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { RWebShare } from "react-web-share";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import BadgeIcon from "@mui/icons-material/Badge";
import RecommendIcon from "@mui/icons-material/Recommend";
import axios from "axios";
import { useAPI } from "../../../apiContext";
import Swal from "sweetalert2";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 1 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const ShareManage = () => {
	const [submitting, setSubmitting] = useState(false);
	const { databyEmail, token } = useAPI();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [recomendedText, setRecomendedText] = useState();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const recomendedTxt = await axios
					.get(`${process.env.REACT_APP_API_PATH}/recomendedText`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then((res) => {
						setRecomendedText(recomendedTxt);
					});
			} catch (error) {
				if (error.response.status) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data,
					});
				} else {
					console.log(error);
				}
			}
		};

		if (token) {
			fetchData();
		}
	}, [token]);

	useEffect(() => {
		setProfileShareText(databyEmail?.shareText?.profileShareText);
		setRecommendShareText(recomendedText?.recommendShareText);
	}, [databyEmail, recomendedText]);

	const [edit, setEdit] = React.useState(false);
	const [profileShareText, setProfileShareText] = React.useState();
	const [recommendShareText, setRecommendShareText] = React.useState();

	return (
		<Box sx={{ p: 2 }}>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					mb: 1.5,
					"& .Mui-selected": {
						backgroundColor: "gray !important",
						pointerEvents: "none !important"
					},
				}}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold", fontSize: "1.8rem" }}>
					Share Text Manage
				</Typography>
				<Tabs
					value={value}
					onChange={handleChange}
					variant='fullWidth'
					aria-label='basic tabs example'>
					<Tab icon={<BadgeIcon />} label='Profile' {...a11yProps(0)} />
					<Tab icon={<RecommendIcon />} label='Recommend' {...a11yProps(1)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<Box sx={{ position: "relative" }}>
					{!edit && (
						<EditIcon
							className='textColor borderColor'
							onClick={() => setEdit(true)}
							sx={{
								cursor: "pointer",
								borderRadius: "5px",
								position: "absolute",
								top: 7,
								right: 7,
								zIndex: "1",
								width: "1.4em",
								height: "1.4em",
								border: "1px solid",
							}}
						/>
					)}
					{edit ? (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							defaultValue={profileShareText}
							label='Share Text'
							onChange={(e) => setProfileShareText(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					) : (
						<TextField
							disabled
							required
							fullWidth
							multiline
							rows={9}
							value={profileShareText}
							label='Share Text'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				</Box>
				<Box
					sx={{ display: "flex", flexDirection: { md: "row", xs: "column" } }}>
					<Button
						onClick={async () => {
							setSubmitting(true);
							try {
								await axios
									.put(
										`${process.env.REACT_APP_API_PATH}/shareText/${databyEmail?._id}`,
										{
											profileShareText,
											submitTime: new Date().toLocaleString(),
										},
										{
											headers: {
												Authorization: `Bearer ${token}`,
											},
										},
									)
									.then(function (response) {
										setSubmitting(false);
										Swal.fire({
											icon: "success",
											title: "Saved Successfully",
											showConfirmButton: false,
											timer: 1500,
										});
									});
							} catch (error) {
								if (error.response.status) {
									Swal.fire({
										icon: "error",
										title: "Oops...",
										text: error.response.data,
									});
								} else {
									console.log(error);
								}
							}
						}}
						variant='contained'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
							width: "100%",
							my: 1,
						}}>
						Save Text
					</Button>
					<RWebShare
						data={{
							title: databyEmail?.headline?.title,
							text:
								profileShareText || databyEmail?.shareText?.profileShareText,
							url: `${window.location.origin.toString()}/@${databyEmail?.url}`,
						}}
						onClick={() => console.log("shared successfully!")}>
						<Button
							variant='contained'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								width: "100%",
								my: 1,
							}}>
							<ShareIcon sx={{ mr: 0.7 }} /> Share Card
						</Button>
					</RWebShare>
				</Box>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Box sx={{ position: "relative" }}>
					{!edit && (
						<EditIcon
							className='textColor borderColor'
							onClick={() => setEdit(true)}
							sx={{
								cursor: "pointer",
								borderRadius: "5px",
								position: "absolute",
								top: 7,
								right: 7,
								zIndex: "1",
								width: "1.4em",
								height: "1.4em",
								border: "1px solid",
							}}
						/>
					)}
					{edit ? (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							defaultValue={recommendShareText}
							label='Share Text'
							onChange={(e) => setRecommendShareText(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					) : (
						<TextField
							disabled
							required
							fullWidth
							multiline
							rows={9}
							value={recommendShareText}
							label='Share Text'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				</Box>
				<Box
					sx={{ display: "flex", flexDirection: { md: "row", xs: "column" } }}>
					{databyEmail?.role === "Admin" && (
						<Button
							onClick={async () => {
								setSubmitting(true);
								try {
									await axios
										.put(
											`${process.env.REACT_APP_API_PATH}/recomendedText`,
											{
												recommendShareText,
												type: "recomendedText",
												submitTime: new Date().toLocaleString(),
											},
											{
												headers: {
													Authorization: `Bearer ${token}`,
												},
											},
										)
										.then(function (response) {
											setSubmitting(false);
											Swal.fire({
												icon: "success",
												title: "Saved Successfully",
												showConfirmButton: false,
												timer: 1500,
											});
										});
								} catch (error) {
									if (error.response.status) {
										Swal.fire({
											icon: "error",
											title: "Oops...",
											text: error.response.data,
										});
									} else {
										console.log(error);
									}
								}
							}}
							variant='contained'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								width: "100%",
								my: 1,
							}}>
							{recomendedText?.type ? "Save Text" : "Publish Text"}
						</Button>
					)}
					<RWebShare
						data={{
							title: databyEmail?.headline?.title,
							text: recommendShareText || recomendedText?.recommendShareText,
							url: window.location.origin.toString(),
						}}
						onClick={() => console.log("shared successfully!")}>
						<Button
							variant='contained'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								width: "100%",
								my: 1,
							}}>
							<ShareIcon sx={{ mr: 0.7 }} /> Recommend Others
						</Button>
					</RWebShare>
				</Box>
			</TabPanel>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default ShareManage;
