import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import auth from "../../Firebase/firebase.config";
import Swal from "sweetalert2";

export default function ResetPass({ setResetpass }) {
	const [email, setEmail] = React.useState("");
	const [sendPasswordResetEmail, sending, error] =
		useSendPasswordResetEmail(auth);
	React.useEffect(() => {
		if (error) {
			Swal.fire({
				icon: "error",
				title: `${error?.message}`,
				showConfirmButton: true,
			});
		}
	}, [error]);

	return (
		<Box>
			<Box sx={{ p: 3 }}>
				<Typography variant='h5' mb={2} className='textColor'>
					Reset Password
				</Typography>
				<Grid container spacing={1.3}>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id='email'
							label='Email Address'
							name='email'
							autoComplete='off'
							autoFocus
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							variant='body2'
							className='textColor'
							sx={{ textDecoration: "underline", cursor: "pointer" }}
							onClick={() => setResetpass(false)}>
							Remembered your password ?
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Button className='bgColor'
							sx={{ px: 2 }}
							onClick={async () => {
								await sendPasswordResetEmail(email);
								Swal.fire({
									icon: "success",
									title: `Check Email for Reset Link`,
									showConfirmButton: true,
								}).then(() => {
									setResetpass(false);
								});
							}}>
							Reset Password
						</Button>
					</Grid>
				</Grid>
			</Box>

			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={sending}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
}
