import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import GetAuth from "../GetAuth";
import axios from "axios";
import Swal from "sweetalert2";

const RequireAuth = ({ children }) => {
	const { user, loading } = GetAuth();
	const location = useLocation();

	const [userRole, setUserRole] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`,
				);
				const { role } = response.data;
				if (role) {
					Swal.fire({
						icon: "success",
						title: "Welcome to the Dashboard",
						text: "You are now logged in",
						confirmButtonText: "OK",
						confirmButtonColor: "#ff0000",
					});
				} else {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "You are not allowed to access this page",
						confirmButtonText: "OK",
						confirmButtonColor: "#ff0000",
					});
				}
				setUserRole(role);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		};

		if (user && !loading) {
			fetchData();
		} else if (!user && !loading) {
			setIsLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Login first to access this page",
				confirmButtonText: "OK",
				confirmButtonColor: "#ff0000",
			});
		}
	}, [user, loading]);

	if (isLoading || loading) {
		return (
			<div
				style={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<span className='loader'></span>
				<span className='loader2'>Loading</span>
			</div>
		);
	}

	if (userRole && user) {
		return children;
	}

	return <Navigate to='/login' state={{ from: location }} replace />;
};

export default RequireAuth;
