import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAPI } from "../../../apiContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Backdrop, Box, CircularProgress, Divider } from "@mui/material";
import Stripe from "stripe";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRECT_KEY);

const Payment = () => {
	const { databyEmail, token } = useAPI();
	const [paymentInfo, setPaymentInfo] = React.useState();
	useEffect(() => {
		if (databyEmail?.url) {
			const fetchData = async () => {
				try {
					const paymentInfos = await axios.get(
						`${process.env.REACT_APP_API_PATH}/paymentsByUser/${databyEmail?.url}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);

					setPaymentInfo(paymentInfos?.data);
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			};

			if (token) {
				fetchData();
			}
		}
	}, [databyEmail, token]);

	const [packageSelected, setPackageSelected] = useState();

	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_API_PATH}/singlePack/${paymentInfo?.package}`,
			)
			.then((res) => {
				setPackageSelected(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [paymentInfo?.package]);

	const [isSubscribed, setIsSubscribed] = React.useState({});

	useEffect(() => {
		if (databyEmail && paymentInfo && paymentInfo.customerId !== "") {
			const getSubscriptionDetails = async (customerId) => {
				try {
					const subscriptions = await stripe.subscriptions.list({
						customer: customerId,
						status: "all",
					});
					setIsSubscribed(subscriptions.data[0]);
				} catch (error) {
					console.error("Error retrieving subscription details:", error);
				}
			};

			getSubscriptionDetails(paymentInfo?.customerId);
		}
	}, [databyEmail, paymentInfo]);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "90vh",
			}}>
			{!paymentInfo || !databyEmail || !isSubscribed ? (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			) : (
				<>
					{(isSubscribed?.status !== "active" ||
						isSubscribed?.status !== "trialing") &&
					paymentInfo?.subscriptionStarted === false ? (
						<Card
							variant='outlined'
							sx={{ width: "330px", borderRadius: "25px" }}>
							<Box
								className='bgColor'
								sx={{
									color: "white !important",
								}}>
								<Typography variant='h5' sx={{ py: 1 }}>
									{paymentInfo?.package}
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									my: 1.5,
								}}>
								{paymentInfo?.features?.map((feature, k) => (
									<Box
										key={k}
										sx={{
											display: "flex",
											my: 0.5,
										}}>
										{feature.status ? (
											<DoneIcon sx={{ color: "green" }} />
										) : (
											<CloseIcon sx={{ color: "red" }} />
										)}
										<Typography
											className='textColor'
											sx={{
												mx: 1,
												fontSize: "18px",
											}}>
											{feature?.id}
										</Typography>
										{feature?.name === "Storage" && (
											<>
												<Typography
													sx={{ fontSize: "18px", fontWeight: "bold" }}
													className='textColor'>
													{feature.storage} MB
												</Typography>
											</>
										)}
									</Box>
								))}
							</Box>

							<Box
								sx={{
									"& .packBtn:hover": {
										backgroundColor: "white !important",
										color: "black !important",
										border: "1px solid",
									},
									"& .packBtnConfirm:hover": {
										backgroundColor: "white !important",
										color: "green !important",
										border: "1px solid green",
									},
								}}>
								<Divider sx={{ borderColor: "white" }} />
								<Box
									className='bgColor '
									sx={{
										color: "white !important",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										border: "1px solid transparent",
									}}>
									<DoneIcon />{" "}
									<Typography variant='h6' sx={{ py: 1, ml: 1 }}>
										Choose Subscription Type
									</Typography>
								</Box>
								<Box sx={{ display: "flex" }}>
									<Box
										onClick={() => {
											paymentInfo?.package === "Premium"
												? window.open(
														`${process.env.REACT_APP_PREMIUM_MONTH}?prefilled_email=${databyEmail?.email}`,
														"_blank",
												  )
												: window.open(
														`${process.env.REACT_APP_STANDRAD_MONTH}?prefilled_email=${databyEmail?.email}`,
														"_blank",
												  );
										}}
										className='bgColor packBtn'
										sx={{
											color: "white !important",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											cursor: "pointer",
											border: "1px solid white",
											width: "100%",
											borderRadius: "0 0 0 25px",
											py: 1,
										}}>
										<Typography variant='h6'>
											<span>{packageSelected?.priceMonthly}$</span>
											<span> / Month</span>
										</Typography>
									</Box>
									<Box
										onClick={() => {
											paymentInfo?.package === "Premium"
												? window.open(
														`${process.env.REACT_APP_PREMIUM_YEAR}?prefilled_email=${databyEmail?.email}`,
														"_blank",
												  )
												: window.open(
														`${process.env.REACT_APP_STANDRAD_YEAR}?prefilled_email=${databyEmail?.email}`,
														"_blank",
												  );
										}}
										className='bgColor packBtn'
										sx={{
											color: "white !important",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											cursor: "pointer",
											border: "1px solid white",
											width: "100%",
											borderRadius: "0 0 25px 0",
											py: 1,
										}}>
										<Typography variant='h6'>
											<span>{packageSelected?.priceYearly}$</span>
											<span> / Year</span>
											<br />
											<div style={{ fontSize: "0.9rem", marginTop: -5 }}>
												( Save{" "}
												{parseFloat(packageSelected?.priceMonthly) * 12 -
													parseFloat(packageSelected?.priceYearly) || 0}
												$)
											</div>
										</Typography>
									</Box>
								</Box>
							</Box>
						</Card>
					) : (
						<>
							<Card
								variant='outlined'
								sx={{ width: "330px", borderRadius: "25px" }}>
								<Box
									className='bgColor'
									sx={{
										color: "white !important",
									}}>
									<Typography variant='h5' sx={{ py: 1 }}>
										{paymentInfo?.package}
									</Typography>

									<Divider sx={{ borderColor: "white !important", my: 1 }} />
									<Typography>
										<span style={{ fontSize: "3.7rem" }}>
											${isSubscribed?.plan?.amount / 100}
										</span>
										<span style={{ fontSize: "1.7rem" }}>
											/{isSubscribed?.items?.data[0]?.plan?.interval}
										</span>
									</Typography>
								</Box>
								<CardContent>
									<p>
										Subscription Status:{" "}
										<span className='textColor'>{isSubscribed?.status}</span>
									</p>
									<p>
										Subscription Session Start Time:{" "}
										<span className='textColor'>
											{new Date(
												isSubscribed?.current_period_start * 1000,
											).toLocaleDateString()}
										</span>
									</p>
									<p>
										Subscription Session End Time:{" "}
										<span className='textColor'>
											{new Date(
												isSubscribed?.current_period_end * 1000,
											).toLocaleDateString()}
										</span>
									</p>
									<p>
										Subscription Session End in:{" "}
										<span className='textColor'>
											{Math.floor(
												(isSubscribed?.current_period_end * 1000 - Date.now()) /
													(1000 * 60 * 60 * 24),
											)}{" "}
											days
										</span>
									</p>
								</CardContent>
								<Box
									sx={{
										"& .packBtn:hover": {
											backgroundColor: "white !important",
											color: "green !important",
											border: "1px solid green",
										},
									}}>
									<Divider sx={{ borderColor: "white" }} />
									<Box
										onClick={() => {
											window.open(
												`${process.env.REACT_APP_MANAGE_SUBS}?prefilled_email=${databyEmail?.email}`,
												"_blank",
											);
										}}
										className='bgColor packBtn'
										sx={{
											color: "white !important",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											cursor: "pointer",
											borderRadius: "0 0 25px 25px",
											border: "1px solid transparent",
										}}>
										<ManageAccountsIcon />{" "}
										<Typography variant='h6' sx={{ py: 1, ml: 1 }}>
											Manage Subscription
										</Typography>
									</Box>
								</Box>
							</Card>
						</>
					)}
				</>
			)}
		</Box>
	);
};

export default Payment;
