import {
	Box,
	Button,
	Container,
	Divider,
	Grid,
	Pagination,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { useAPI } from "../../../../apiContext";

const Video = () => {
	const { selecetedData: data, findFeature } = useAPI();
	const [page, setPage] = useState(0);
	return (
		<>
			{findFeature("Videos")?.status === true && (
				<Container sx={{ pt: 3 }}>
					<Box
						sx={{ border: "3px solid", borderRadius: "15px", p: 1 }}
						className='borderColor'>
						<Typography
							className='textColor'
							variant='h4'
							component='div'
							gutterBttom>
							{data?.headline?.video}
							<Divider className={`${data?.color?.hr}`} />
						</Typography>
						{data?.videos?.length > 0 ? (
							<Grid container spacing={2}>
								<Grid item xs={12} sx={{ mx: "auto" }}>
									<ReactPlayer
										className='borderColor react-player'
										width='100%'
										url={data?.videos[page]?.videoLink}
										controls
									/>
								</Grid>
								{(data?.videos[page]?.buttonLink !== "" ||
									data?.videos[page]?.buttonText !== "") && (
									<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
										<Typography
											className='textColor'
											variant='body2'
											component='div'>
											{data?.videos[page]?.title}
										</Typography>
										<Button
											onClick={() =>
												window.open(
													`${data?.videos[page]?.buttonLink}`,
													"_blank",
												)
											}
											sx={{
												px: 4,
												fontWeight: "bold",
												mt: 1,
												py: 0.4,
												border: "1px solid",
												borderRadius: "7px",
												textTransform: "capitalize",
											}}>
											{data?.videos[page]?.buttonText}
										</Button>
									</Grid>
								)}
								<Grid
									className='pagination'
									item
									md={9}
									xs={12}
									sx={{ mx: "auto" }}>
									<Pagination
										sx={{
											display: "flex",
											justifyContent: "center !important",
										}}
										count={data?.videos?.length}
										onChange={(e, value) => setPage(value - 1)}
										variant='outlined'
										shape='rounded'
									/>
								</Grid>
							</Grid>
						) : (
							<Typography
								className='textColor'
								sx={{ mb: 0.5, fontWeight: 900 }}
								variant='h6'
								component='div'
								gutterBottom>
								No Videos !...
							</Typography>
						)}
					</Box>
				</Container>
			)}
		</>
	);
};

export default Video;
