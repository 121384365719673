import {
	Backdrop,
	Card,
	CircularProgress,
	Container,
	Fade,
	Modal,
} from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	maxWidth: "95vw",
	width: { md: "90vw", sm: "100vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const ViewUser = ({ open, setOpen, submit }) => {
	const columns = [
		{
			field: "date",
			headerName: "Date",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "qrScanEvents",
			headerName: "QR Scan",
			minWidth: 310,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						{params.row?.city}, {params.row?.country}{" "}
						<img src={params.row?.countryFlag} alt={params.row?.country} />
					</>
				);
			},
		},
	];
	return (
		<Container>
			{submit ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style}>
							<Box>
								<Card
									className='borderColor'
									sx={{
										p: 2,
										my: 1,
										minWidth: "300px",
										display: "flex",
										flexDirection: "column",
										alignContent: "center",
										overflow: "visible",
										border: "2px solid ",
										textAlign: "left",
										borderRadius: 3,
										"& table, th, td": {
											border: "1px solid black",
											borderCollapse: "collapse",
											padding: 0.5,
										},
									}}>
									<CancelIcon
										fontSize='large'
										onClick={() => setOpen(false)}
										className='textColor'
										sx={{
											position: "fixed",
											top: "13px",
											right: "5px",
											cursor: "pointer",
											zIndex: 100,
										}}
									/>
									{submit && (
										<Box
											sx={{
												width: "100%",
												height: { md: "80vh", xs: "90vh" },
												"& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
													border: "1px solid",
													fontSize: "0.75rem",
													padding: "0px 5px",
													textTransform: "capitalize",
												},
												"& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
													fontSize: "1rem",
												},
											}}>
											<DataGrid
												componentsProps={{
													panel: {
														sx: {
															"& .MuiDataGrid-panelContent": {
																maxWidth: "100vw !important",
															},
														},
													},
												}}
												rows={submit?.qrScanEvents || []}
												getRowId={(row) => row?.id}
												columns={columns}
												components={{
													Toolbar: GridToolbar,
												}}
												disableSelectionOnClick
											/>
										</Box>
									)}
								</Card>
							</Box>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</Container>
	);
};

export default ViewUser;
