import React, { useState } from "react";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";
import Headline from "../Headline/Headline";
import { useAPI } from "../../../apiContext";

const AddVideo = ({ featureActiveBtn, findFeature }) => {
	const {
		loading,
		setLoading,
		databyEmail,
		findFeature: find,
		token,
	} = useAPI();

	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = async () => {
		setLoading(true);
		const uploadData = {
			type: "Video",
			videoId: `video${Math.floor(100000 + Math.random() * 900000)}`,
			videoLink: inputs?.videoLink || "",
			title: inputs?.title || "",
			buttonText: inputs?.buttonText || "",
			buttonLink: inputs?.buttonLink || "",
			submitTime: new Date().toLocaleString(),
		};
		try {
			await axios
				.put(
					`${process.env.REACT_APP_API_PATH}/videos/${databyEmail?._id}`,
					uploadData,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
				.then(function (response) {
					Swal.fire({
						icon: "success",
						title: "Video Added Successfully",
						showConfirmButton: false,
						timer: 1500,
					});
					setLoading(false);
					setOpen(false);
					setInputs({});
				});
		} catch (error) {
			if (error.response.status) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.response.data,
				});
			} else {
				console.log(error);
			}
		}
	};

	const handleCancel = () => {
		setOpen(false);
	};
	const [inputs, setInputs] = useState({
		videoLink: "",
		title: "",
		buttonText: "",
		buttonLink: "",
	});

	const handleDeleteClick = (video) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				try {
					await axios
						.put(
							`${process.env.REACT_APP_API_PATH}/deleteVideos/${databyEmail?._id}/${video?.videoId}`,
							{},
							{
								headers: {
									Authorization: `Bearer ${token}`,
								},
							},
						)
						.then(function (response) {
							setLoading(false);
							Swal.fire("Deleted!", "Your file has been deleted.", "success");
						});
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			}
		});
	};

	return (
		<Box
			sx={{
				my: 2,
				position: "relative",
				border:
					(window.location.pathname.includes("/dashboard/managevideo")
						? find("Videos")
						: findFeature("Videos")
					)?.status !== true && "3px solid red !important",
				backgroundColor:
					(window.location.pathname.includes("/dashboard/managevideo")
						? find("Videos")
						: findFeature("Videos")
					)?.status !== true && "#ff000038 !important",
			}}>
			{" "}
			{!window.location.pathname.includes("/dashboard/managevideo") && (
				<Headline type={"video"} />
			)}
			<Box
				style={{
					padding: 15,
					margin: 0,
					borderRadius: 7,
				}}>
				<Button variant='outlined' onClick={handleOpen} sx={{ mb: 2 }}>
					Upload New Item
				</Button>
				<Box sx={{ maxHeight: "80vh", overflowY: "scroll" }}>
					{databyEmail?.videos?.length > 0 ? (
						<Grid container spacing={2}>
							{databyEmail?.videos?.map((vid, key) => (
								<Grid
									key={key}
									item
									xs={
										window.location.pathname.includes("/dashboard/managevideo")
											? 4
											: 6
									}>
									<Box sx={{ position: "relative" }}>
										<ReactPlayer
											className='borderColor react-player'
											width='100%'
											url={vid?.videoLink}
											controls
										/>
										<Box
											sx={{
												position: "absolute",
												bottom: 0,
												left: 0,
												right: 0,
												px: 1,
												backgroundColor: "rgba(0, 0, 0, 0.5)",
												color: "white",
												display: "flex",
												justifyContent: "space-between",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Typography>{vid?.title?.slice(0, 10)}....</Typography>
											{vid?.buttonText && vid?.buttonLink && (
												<Box sx={{ flex: "0 0 auto" }}>
													<Button
														variant='outlined'
														size='small'
														sx={{ mr: 1 }}
														onClick={() =>
															window.open(vid?.buttonLink, "_blank")
														}>
														{vid?.buttonText}
													</Button>
													<IconButton
														onClick={() => handleDeleteClick(vid)}
														sx={{ color: "white" }}>
														<DeleteIcon />
													</IconButton>
												</Box>
											)}
										</Box>
									</Box>
								</Grid>
							))}
						</Grid>
					) : (
						<Typography
							className='textColor'
							sx={{ mb: 0.5, fontWeight: 900 }}
							variant='h6'
							component='div'
							gutterBottom>
							No Videos !...
						</Typography>
					)}
				</Box>
			</Box>{" "}
			<Dialog open={open} onClose={handleClose}>
				{!loading ? (
					<>
						<DialogTitle>Add Video</DialogTitle>
						<DialogContent>
							<Box
								sx={{
									my: 2,
									textAlign: "left",
									"& table tr td": {
										p: 0.3,
									},
								}}>
								<table>
									<tr>
										<td>Video Link : </td>
										<td>
											<input
												type='text'
												value={inputs.videoLink}
												onChange={(e) =>
													setInputs({ ...inputs, videoLink: e.target.value })
												}
												placeholder='Video Link'
											/>
										</td>
									</tr>
									<tr>
										<td>Title : </td>
										<td>
											<input
												type='text'
												value={inputs.title}
												onChange={(e) =>
													setInputs({ ...inputs, title: e.target.value })
												}
												placeholder='Title'
											/>
										</td>
									</tr>
									<tr>
										<td>Button Text : </td>
										<td>
											<input
												type='text'
												value={inputs.buttonText}
												onChange={(e) =>
													setInputs({ ...inputs, buttonText: e.target.value })
												}
												placeholder='Button Text'
											/>
										</td>
									</tr>
									<tr>
										<td>Button Link : </td>
										<td>
											<input
												type='text'
												value={inputs.buttonLink}
												onChange={(e) =>
													setInputs({ ...inputs, buttonLink: e.target.value })
												}
												placeholder='Button Link'
											/>
										</td>
									</tr>
								</table>
							</Box>
						</DialogContent>
						<DialogActions
							sx={{ display: "flex", justifyContent: "space-between" }}>
							<Button onClick={handleCancel}>Cancel</Button>
							<div>
								<IconButton
									onClick={() =>
										setInputs({
											videoLink: "",
											title: "",
											buttonText: "",
											buttonLink: "",
										})
									}
									sx={{ mr: 1 }}>
									<CancelIcon />
								</IconButton>
								<IconButton onClick={handleSave}>
									<SaveAltIcon />
								</IconButton>
							</div>
						</DialogActions>
					</>
				) : (
					<Backdrop
						open={open}
						sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
						<CircularProgress
							color='primary'
							size={60}
							sx={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
							}}
						/>
					</Backdrop>
				)}
			</Dialog>
		</Box>
	);
};

export default AddVideo;
