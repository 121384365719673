import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Backdrop } from "@mui/material";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "30vw", sm: "60vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const HowToInstall = ({ iphoneOpen, setIphoneOpen }) => {
	return (
		<div>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={iphoneOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={iphoneOpen}>
					<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
						<CancelIcon
							onClick={() => setIphoneOpen(false)}
							className='textColor'
							sx={{
								position: "fixed",
								top: "5px",
								right: "5px",
								cursor: "pointer",
							}}
						/>
						<img
							src='/howtoinstall.png'
							alt=''
							srcset=''
							style={{ width: "100%" }}
						/>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
};

export default HowToInstall;
