import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Chip } from "@mui/material";
import { useAPI } from "../../../apiContext";
import Swal from "sweetalert2";

const Refferels = () => {
	const { databyEmail, token } = useAPI();
	const [allRefferelInfo, setAllRefferelInfo] = useState();

	useEffect(() => {
		if (databyEmail?.url) {
			const fetchData = async () => {
				try {
					const refferedUsers = await axios.get(
						`${process.env.REACT_APP_API_PATH}/refferedUsers/${databyEmail?.url}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);

					setAllRefferelInfo(refferedUsers?.data);
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			};

			if (token) {
				fetchData();
			}
		}
	}, [databyEmail, token]);
	const columns = [
		{
			field: "user",
			headerName: "User",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "subscriptionStatus",
			headerName: "Status",
			minWidth: 200,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						{params.row?.subscriptionStatus === "active" ||
						params.row?.subscriptionStatus === "trialing" ? (
							<Chip label={params.row?.subscriptionStatus} color='success' />
						) : (
							<Chip label={params.row?.subscriptionStatus} color='error' />
						)}
					</>
				);
			},
		},

		{
			field: "subscriptionStartedTime",
			headerName: "Subscription Started Time",
			minWidth: 200,
			flex: 1,
			renderCell: (params) => {
				return (
					<>{params.row?.subscriptionStartedTime || "Not Subscribed Yet"}</>
				);
			},
		},
		{
			field: "package",
			headerName: "Package",
			minWidth: 200,
			flex: 1,
		},
	];
	return (
		<Box sx={{ p: 2 }}>
			{databyEmail && allRefferelInfo && (
				<Box
					sx={{
						width: "100%",
						height: { md: "80vh", xs: "90vh" },
						"& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
							border: "1px solid",
							fontSize: "0.75rem",
							padding: "0px 5px",
							textTransform: "capitalize",
						},
						"& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
							fontSize: "1rem",
						},
					}}>
					<DataGrid
						componentsProps={{
							panel: {
								sx: {
									"& .MuiDataGrid-panelContent": {
										maxWidth: "100vw !important",
									},
								},
							},
						}}
						rows={allRefferelInfo || []}
						getRowId={(row) => row?.user}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}
		</Box>
	);
};

export default Refferels;
