import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/material";
import Typewriter from "typewriter-effect";
import { useAPI } from "../../../../apiContext";
import { Wave } from "react-animated-text";
import ShortInfo from "../ShortInfo/ShortInfo";

const Banner = () => {
	const { selecetedData: data, findFeature } = useAPI();

	return (
		<Container sx={{ pt: 3 }}>
			<Box
				sx={{
					border:
						findFeature("Profile")?.status === true &&
						findFeature("Headlines")?.status === true &&
						"3px solid",
					borderRadius: "15px",
					overflow: "hidden",
				}}
				className='borderColor'>
				<Card
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						boxShadow: 0,
						position: "relative",
						backgroundColor: "transparent",
					}}>
					{findFeature("Profile")?.status === true && (
						<>
							<CardMedia
								className='bannerPhoto'
								component='img'
								alt=''
								sx={{
									height: "100%",
									position: "relative",
									backgroundSize: "cover",
									objectFit: "fill",
								}}
								image={data?.banner?.imageLink2 || "/sample-banner.jpg"}
							/>
							<Box
								sx={{
									height: { md: 200, xs: 150 },
									width: { md: 200, xs: 150 },
									mt: { md: -11, xs: -7 },
									position: "relative",
								}}>
								<CardMedia
									className='profilePhoto'
									sx={{
										boxShadow: 11,
										borderRadius: "50%",
										width: "100%",
										height: "100%",
									}}
									component='img'
									alt={data?.headline?.title}
									image={data?.profile?.imageLink2 || "/user.jpg"}
								/>
							</Box>
						</>
					)}

					<CardContent>
						{findFeature("Headlines")?.status === true && (
							<>
								<Typography
									className='textColor title'
									sx={{ fontWeight: 500, fontSize: "1.7rem" }}
									component='div'
									gutterBottom>
									<Wave
										text={data?.headline?.title || ""}
										effect='jump'
										effectChange={0.5}
										delay={2}
									/>
								</Typography>
								<Typography
									className='textColor'
									sx={{ fontWeight: 600, fontSize: "1.1rem" }}
									component='div'>
									<Typewriter
										options={{
											strings: [`${data?.headline?.subtitle || ""}`],
											autoStart: true,
											loop: true,
										}}
									/>
								</Typography>
							</>
						)}
						<ShortInfo />
					</CardContent>
				</Card>
			</Box>
		</Container>
	);
};

export default Banner;
