import { Button, Grid, Typography } from "@mui/material";

import { Box } from "@mui/system";
import React from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useAPI } from "../../../apiContext";
import InActiveCard from "../InActiveCard/InActiveCard";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Support from "./Support/Support";
import AllCharts from "./AllCharts";
import GetAuth from "../../../GetAuth";
import Swal from "sweetalert2";
import HtmlTooltip from "../HtmlTooltip";
import { reactLocalStorage } from "reactjs-localstorage";

const MyProfile = () => {
	const { databyEmail, setLoading, findFeature, toltips, setToltips } =
		useAPI();
	const [open, setOpen] = React.useState(false);
	const [edit, setEdit] = React.useState(false);
	const { token } = GetAuth();

	return (
		<Box sx={{ p: 2 }}>
			{!edit && databyEmail?.status === "Active" ? (
				<>
					<Grid
						container
						spacing={2}
						sx={{
							alignItems: "center",
							justifyContent: "center",
						}}>
						<Grid item md={4} xs={12}>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<img
									src={databyEmail?.profile?.imageLink2 || "/user.jpg"}
									style={{
										width: "50px",
										height: "50px",
										borderRadius: "50%",
										border: "2px solid",
									}}
									className='borderColor'
									alt=''
								/>
								&nbsp;&nbsp; &nbsp;&nbsp;
								<Typography
									className='textColor'
									variant='h6'
									sx={{
										fontWeight: "bold",
									}}>
									Hello, {databyEmail?.headline?.title || "N/A"}
								</Typography>{" "}
							</Box>
						</Grid>
						<Grid item md={4} xs={12}>
							<HtmlTooltip
								open={toltips?.profileLink}
								title={
									<Box>
										<Typography className='textColor'>
											Your card link, share this link with your friends and they
											can see your card.
										</Typography>
										<Button
											sx={{ border: "1px solid", py: 0, mt: 1.5 }}
											onClick={() => {
												setToltips({
													...toltips,
													profileLink: false,
													contactSupport: true,
												});
												reactLocalStorage.setObject("toltips", {
													...toltips,
													profileLink: false,
													contactSupport: true,
												});
											}}>
											Ok
										</Button>
									</Box>
								}>
								<a
									className='textColor'
									href={`${window.location.origin.toString()}/@${
										databyEmail?.url
									}`}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<PermIdentityIcon className='textColor' sx={{ mr: 1 }} />
										<Typography
											className='textColor'
											sx={{ fontWeight: "bold" }}>
											{`${window.location.origin.toString()}/@${
												databyEmail?.url
											}`}
										</Typography>
									</Box>
								</a>
							</HtmlTooltip>
						</Grid>
						<Grid item md={2} xs={12}>
							<HtmlTooltip
								open={toltips?.contactSupport}
								title={
									<Box>
										<Typography className='textColor'>
											Use this button to contact support team for any query or
											issue you are facing with your card or account or any
											other issue related to the website or app.
										</Typography>
										<Button
											sx={{ border: "1px solid", py: 0, mt: 1.5 }}
											onClick={() => {
												setToltips({
													...toltips,
													contactSupport: false,
													editPrevBtn: true,
												});
												reactLocalStorage.setObject("toltips", {
													...toltips,
													contactSupport: false,
													editPrevBtn: true,
												});
											}}>
											Ok
										</Button>
									</Box>
								}>
								<Button
									sx={{ border: "1px solid", px: 2 }}
									onClick={() => setOpen(true)}>
									<ContactSupportIcon /> Contact Support
								</Button>
							</HtmlTooltip>
						</Grid>
						<Grid item md={2} xs={12}>
							<HtmlTooltip
								open={toltips?.editPrevBtn}
								title={
									<Box>
										<Typography className='textColor'>
											Use this button to edit/preview your card
										</Typography>
										<Button
											sx={{ border: "1px solid", py: 0, mt: 1.5 }}
											onClick={() => {
												setToltips({
													...toltips,
													editPrevBtn: false,
													closeEditingMode: true,
												});
												reactLocalStorage.setObject("toltips", {
													...toltips,
													editPrevBtn: false,
													closeEditingMode: true,
												});
												setEdit(!edit);
											}}>
											Ok
										</Button>
									</Box>
								}>
								<Button
									onClick={() => {
										Swal.fire({
											title: "Are you sure?",
											text: `You want to ${
												edit ? "Preview" : "Edit"
											} your card`,
											icon: "warning",
											showCancelButton: true,
											confirmButtonColor: "#3085d6",
											cancelButtonColor: "#d33",
											confirmButtonText: "Yes",
										}).then((result) => {
											if (result.isConfirmed) {
												setEdit(!edit);
											}
										});
									}}
									sx={{
										color: "white",
										border: "1px solid",
										px: 2,
									}}>
									Edit / Preview Card
								</Button>
							</HtmlTooltip>
						</Grid>

						<Grid item xs={12}>
							<Grid container spacing={2}>
								{databyEmail && (
									<AllCharts
										databyEmail={databyEmail}
										findFeature={findFeature}
										token={token}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</>
			) : (
				<InActiveCard
					edit={edit}
					setEdit={setEdit}
					databyEmail={databyEmail}
					setLoading={setLoading}
					token={token}
					toltips={toltips}
					setToltips={setToltips}
				/>
			)}

			{open && (
				<Support
					open={open}
					setOpen={setOpen}
					user={databyEmail}
					token={token}
				/>
			)}
		</Box>
	);
};

export default MyProfile;
