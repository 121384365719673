import React, { useEffect, useLayoutEffect, useState } from "react";
import Reviews from "./Components/Reviews/Reviews";
import About from "./Components/About/About";
import Banner from "./Components/Banner/Banner";
import Buttons from "./Components/Buttons/Buttons";
import Contact from "./Components/Contact/Contact";
import Gallery from "./Components/Gallery/Gallery";
import CopyRight from "./Components/CopyRight/CopyRight";
import Video from "./Components/Video/Video";
import Device from "react-device-frame";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import { Fab, Typography } from "@mui/material";
import ShareButton from "./Components/ShareButton/ShareButton";
import Map from "./Components/Map/Map";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { useAPI } from "../../apiContext";

const HomepageMain = ({ url, data }) => {
	const { selecetedData } = useAPI();

	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	const [mobile, setMobile] = useState();
	useEffect(() => {
		if (size?.[0] < 1080) {
			setMobile(true);
		} else if (window.location.pathname.includes("createAccount")) {
			setMobile(false);
		} else {
			setMobile(false);
		}
	}, [size]);
	const urlNow = window.location.pathname;
	if (!selecetedData) {
		return (
			<div
				style={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<span className='loader'></span>
				<span className='loader2'>Loading</span>
			</div>
		);
	}
	return (
		<div
			style={{
				pointerEvents:
					(urlNow.includes("/dashboard") ||
						urlNow.includes("/createAccount")) &&
					"none",
			}}>
			{data?.status === "Active" ? (
				<>
					{size?.[0] > 1080 &&
						!window.location.pathname.includes("createAccount") && (
							<div
								style={{
									position: "fixed",
									right: "9px",
									top: "9px",
									zIndex: "9999",
								}}>
								{!mobile && (
									<Fab
										onClick={() => setMobile(true)}
										color='primary'
										aria-label='add'>
										<PhoneAndroidIcon />
									</Fab>
								)}
								{mobile && (
									<Fab
										onClick={() => setMobile(false)}
										color='primary'
										aria-label='add'>
										<DesktopWindowsIcon />
									</Fab>
								)}
							</div>
						)}
					{size?.[0] > 1080 && mobile ? (
						<div className='home'>
							<Device name='galaxy-note8' url={url} />
						</div>
					) : (
						<div>
							<Banner />
							<Buttons />
							<About />
							<Video />
							<Gallery />
							<Reviews />
							<Map />
							<Contact />
							<CopyRight />
							{!window.location.pathname.includes("createAccount") && (
								<ShareButton />
							)}
						</div>
					)}
				</>
			) : (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						minHeight: "100vh",
					}}>
					<NoAccountsIcon
						className='textColor'
						sx={{
							fontSize: "9rem",
							marginBottom: "1rem",
						}}
					/>
					<Typography
						className='textColor'
						sx={{ fontWeight: "bold" }}
						variant='h3'
						component='div'>
						OPPS!
					</Typography>
					<br />
					<Typography
						className='textColor'
						sx={{ fontWeight: "bold" }}
						variant='h5'
						component='div'>
						This card is not available at the moment.
					</Typography>
				</div>
			)}
		</div>
	);
};

export default HomepageMain;
