import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import { useAPI } from "../../../apiContext";

const Headline = ({ type }) => {
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			title: "",
			subtitle: "",
			contact: "",
			review: "",
			gallery: "",
			aboutUs: "",
			video: "",
			quickContacts: "",
			findUs: "",
		},
	});

	const { setLoading, databyEmail, token } = useAPI();

	useEffect(() => {
		reset(databyEmail?.headline);
	}, [databyEmail, reset]);

	const onSubmit = async ({
		title,
		subtitle,
		contact,
		review,
		gallery,
		aboutUs,
		quickContacts,
		video,
		findUs,
	}) => {
		setLoading(true);
		const data = {
			type: "headline",
			title,
			subtitle,
			quickContacts,
			contact,
			review,
			gallery,
			aboutUs,
			video,
			findUs,
			submitTime: new Date().toLocaleString(),
		};
		try {
			await axios
				.put(
					`${process.env.REACT_APP_API_PATH}/headline/${databyEmail?._id}`,
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
				.then(function (response) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: "Successfully Updated",
						showConfirmButton: false,
						timer: 1500,
					});
				});
		} catch (error) {
			if (error.response.status) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.response.data,
				});
			} else {
				console.log(error);
			}
		}
	};

	return (
		<Box sx={{ my: 2 }}>
			<form onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={2}>
					{type === "banner" && (
						<>
							<Grid item xs={12}>
								<TextField
									required
									size='small'
									name='Title'
									label='Title'
									fullWidth
									InputLabelProps={{
										shrink: true,
									}}
									{...register("title", { required: true })}
								/>
							</Grid>
							<Grid item xs={9}>
								<TextField
									required
									size='small'
									name='SubTitle'
									label='SubTitle'
									fullWidth
									InputLabelProps={{
										shrink: true,
									}}
									{...register("subtitle", { required: true })}
								/>
							</Grid>
						</>
					)}
					{type === "buttons" && (
						<Grid item xs={9}>
							<TextField
								required
								size='small'
								name='Quick Contacts Title'
								label='Quick Contacts Title'
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								{...register("quickContacts", { required: true })}
							/>
						</Grid>
					)}
					{type === "findUs" && (
						<Grid item xs={9}>
							<TextField
								required
								size='small'
								name='Find Us Title'
								label='FInd Us Title'
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								{...register("findUs", { required: true })}
							/>
						</Grid>
					)}
					{type === "about" && (
						<Grid item xs={9}>
							<TextField
								required
								size='small'
								name='About Us Title'
								label='About Us Title'
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								{...register("aboutUs", { required: true })}
							/>
						</Grid>
					)}
					{type === "gallery" && (
						<Grid item xs={9}>
							<TextField
								required
								size='small'
								name='Gallery Title'
								label='Gallery Title'
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								{...register("gallery", { required: true })}
							/>
						</Grid>
					)}
					{type === "video" && (
						<Grid item xs={9}>
							<TextField
								required
								size='small'
								name='Video Gallery Title'
								label='Video Gallery Title'
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								{...register("video", { required: true })}
							/>
						</Grid>
					)}
					{type === "review" && (
						<Grid item xs={9}>
							<TextField
								required
								size='small'
								name='Review Title'
								label='Review Title'
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								{...register("review", { required: true })}
							/>
						</Grid>
					)}
					{type === "contact" && (
						<Grid item xs={9}>
							<TextField
								required
								size='small'
								name='Contact Form Title'
								label='Contact Form Title'
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								{...register("contact", { required: true })}
							/>
						</Grid>
					)}
					<Grid item xs={3}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "90%",
								px: 3,
								fontWeight: "bold",
								borderRadius: "5px",
								border: "1px solid",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default Headline;
