import { Box, Button } from "@mui/material";
import React from "react";
import { useAPI } from "../../../apiContext";
import StatisticsForAdmin from "./StatisticsForAdmin";
import StatisticsForUsers from "./StatisticsForUsers";

const Statistics = () => {
	const { databyEmail, token } = useAPI();
	const [dataToRender, setDataToRender] = React.useState("My Data");
	return (
		<Box sx={{ p: 2 }}>
			{databyEmail?.role === "Admin" ? (
				<>
					<Box sx={{ mb: 2 }}>
						<Button
							sx={{
								backgroundColor:
									dataToRender === "My Data" ? "#3f51b5 !important" : "",
								border: "1px solid #3f51b5",
								borderRadius: "25px 0 0 25px",
								px: 2,
							}}
							onClick={() => {
								setDataToRender("My Data");
							}}>
							My Data
						</Button>
						<Button
							sx={{
								backgroundColor:
									dataToRender === "All Data" ? "#3f51b5 !important" : "",
								border: "1px solid #3f51b5",
								borderRadius: "0 25px 25px 0",
								px: 2,
							}}
							onClick={() => {
								setDataToRender("All Data");
							}}>
							All Data
						</Button>
					</Box>
					{dataToRender === "My Data" ? (
						<StatisticsForUsers databyEmail={databyEmail} token={token} />
					) : (
						<StatisticsForAdmin token={token} />
					)}
				</>
			) : (
				<StatisticsForUsers databyEmail={databyEmail} token={token} />
			)}
		</Box>
	);
};

export default Statistics;
