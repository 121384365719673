import {
	Backdrop,
	Button,
	Card,
	CircularProgress,
	Container,
	Fade,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	maxWidth: "95vw",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const Support = ({ open, setOpen, user, token }) => {
	const { register, handleSubmit, reset } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const onSubmit = async ({ subject, why }) => {
		const datas = {
			supportId: `Support${Math.floor(100000 + Math.random() * 900000)}`,
			subject,
			why,
			userId: user?.url,
			userEmail: user?.email,
			submitTime: new Date().toLocaleString(),
			solved: false,
		};
		setSubmitting(true);

		try {
			await axios
				.post(`${process.env.REACT_APP_API_PATH}/support`, datas, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(function (response) {
					setSubmitting(false);
					Swal.fire({
						icon: "success",
						title:
							"You support request sent successfully, we will contact you soon.",
						showConfirmButton: true,
						timer: 15000,
					});
					reset();
					setOpen(false);
				});
		} catch (error) {
			if (error.response.status) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.response.data,
				});
			} else {
				console.log(error);
			}
		}
	};
	return (
		<Container>
			{user ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style}>
							<Box>
								<Card
									className='borderColor'
									sx={{
										p: 2,
										my: 1,
										minWidth: "300px",
										display: "flex",
										flexDirection: "column",
										alignContent: "center",
										overflow: "visible",
										border: "2px solid ",
										textAlign: "left",
										borderRadius: 3,
									}}>
									<CancelIcon
										onClick={() => setOpen(false)}
										className='textColor'
										sx={{
											position: "fixed",
											top: "13px",
											right: "5px",
											cursor: "pointer",
										}}
									/>
									<Typography
										variant='h6'
										component='div'
										className='textColor'>
										Hello, <b>{user?.url}</b>
									</Typography>
									<Typography
										gutterBottom
										variant='subtitle2'
										component='div'
										className='textColor'>
										Tell us about your problem
									</Typography>
									<Box sx={{ mt: 2 }}>
										<form onSubmit={handleSubmit(onSubmit)} method='post'>
											<TextField
												size='small'
												required
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='subject'
												label='Subject'
												{...register("subject", { required: true })}
											/>
											<TextField
												multiline
												rows={4}
												size='small'
												required
												sx={{ width: "100%", my: 2 }}
												id='outlined-basic'
												name='why'
												label='Tell us about your problem'
												{...register("why", { required: true })}
											/>
											<Button
												className='sendButton'
												type='submit'
												variant='contained'
												sx={{
													width: "100%",
													px: 3,
													fontWeight: "bold",
													borderRadius: "5px",
												}}>
												Submit
											</Button>
										</form>
									</Box>
								</Card>
							</Box>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 999,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 999,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Support;
