import React from "react";

const ChatBot = () => {
	return (
		<div>
			<zapier-interfaces-page-embed
				page-id='cljtlb7np0002mo0ndf2t2vi4'
				no-background='false'
				style={{
					maxWidth: "100%",
					height: "99vh",
				}}></zapier-interfaces-page-embed>
		</div>
	);
};

export default ChatBot;
