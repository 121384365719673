import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
	Backdrop,
	Button,
	Chip,
	CircularProgress,
	Container,
	FormHelperText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAPI } from "../../../apiContext";
import { useEffect } from "react";

const ColorChange = () => {
	const { handleSubmit, reset } = useForm({
		defaultValues: {
			color1st: "",
		},
	});
	const [info, setInfo] = useState();
	const { loading, setLoading, databyEmail, token } = useAPI();
	useEffect(() => {
		reset(databyEmail?.color);
		setInfo(databyEmail?.color);
		setHr(databyEmail?.color?.hr);
		setColor(databyEmail?.color?.color1st);
		setColor2(databyEmail?.color?.color2nd);
		setColor3(databyEmail?.color?.color3rd);
	}, [databyEmail, reset]);

	const [hr, setHr] = React.useState(info?.hr);
	const handleChange = (event) => {
		setHr(event.target.value);
	};
	const [color, setColor] = useState(info?.color1st);
	const [color2, setColor2] = useState(info?.color2nd);
	const [color3, setColor3] = useState(info?.color3rd);
	const onSubmit = async () => {
		setLoading(true);
		const data = {
			color1st: color || info?.color1st,
			color2nd: color2 || info?.color2nd,
			color3rd: color3 || info?.color3rd,
			hr: hr || info?.hr,
			submitTime: new Date().toLocaleString(),
		};
		try {
			await axios
				.put(
					`${process.env.REACT_APP_API_PATH}/color/${databyEmail?._id}`,
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
				.then(function (response) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: "Changed Successfully",
						showConfirmButton: false,
						timer: 1500,
					});
					window.location.reload();
				});
		} catch (error) {
			if (error.response.status) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.response.data,
				});
			} else {
				console.log(error);
			}
		}
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Change Color And Border Style
			</Typography>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					spacing={3}
					display='flex'
					flexDirection='column'
					alignContent='center'
					sx={{ mt: 5 }}>
					<Grid item md={8} sm={8} xs={12}>
						<Chip
							label='Click below to change "Dominant Color"'
							sx={{ mb: 1 }}
						/>
						<input
							style={{ width: "100%", height: "57px" }}
							type='color'
							value={color || info?.color1st}
							onChange={(e) => setColor(e.target.value)}
						/>
						<input
							style={{ width: "100%", height: "45px", padding: "0 1rem" }}
							type='text'
							value={color || info?.color1st}
							onChange={(e) => setColor(e.target.value)}
						/>
					</Grid>
					<Grid item md={8} sm={8} xs={12}>
						<Chip label='Click below to change "Accent Color"' sx={{ mb: 1 }} />
						<input
							style={{ width: "100%", height: "57px" }}
							type='color'
							value={color2 || info?.color2nd}
							onChange={(e) => setColor2(e.target.value)}
						/>
						<input
							style={{ width: "100%", height: "45px", padding: "0 1rem" }}
							type='text'
							value={color2 || info?.color2nd}
							onChange={(e) => setColor2(e.target.value)}
						/>
					</Grid>
					<Grid item md={8} sm={8} xs={12}>
						<Chip
							label='Click below to change "Background Color"'
							sx={{ mb: 1 }}
						/>
						<input
							style={{ width: "100%", height: "57px" }}
							type='color'
							value={color3 || info?.color3rd}
							onChange={(e) => setColor3(e.target.value)}
						/>
						<input
							style={{ width: "100%", height: "45px", padding: "0 1rem" }}
							type='text'
							value={color3 || info?.color3rd}
							onChange={(e) => setColor3(e.target.value)}
						/>
					</Grid>
					{info?.hr && (
						<Grid item md={8} sm={8} xs={12}>
							<FormHelperText>Change Border Style</FormHelperText>
							<FormControl
								sx={{
									width: "100%",
									"& .MuiOutlinedInput-input": { padding: "9px 14px 0px 14px" },
								}}>
								<Select
									value={hr}
									onChange={handleChange}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}>
									<MenuItem value='hr' id='borderChoose'>
										<hr className='hr' />
									</MenuItem>
									<MenuItem value='hr2' id='borderChoose'>
										<hr className='hr2' />
									</MenuItem>
									<MenuItem value='hr3' id='borderChoose'>
										<hr className='hr3' />
									</MenuItem>
									<MenuItem value='hr4' id='borderChoose'>
										<hr className='hr4' />
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
					<Grid item md={6} sm={6} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "5px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ColorChange;
