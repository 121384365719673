import { Box, Container, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAPI } from "../../../../apiContext";

const About = () => {
	const { selecetedData: data, findFeature } = useAPI();
	const [seeMore, setSeeMore] = useState(false);

	const handleToggleSeeMore = () => {
		setSeeMore(!seeMore);
	};

	const renderText = () => {
		if (data?.details?.details.length > 300 && !seeMore) {
			return (
				<>
					{data.details.details.slice(0, 300)}....
					<span
						onClick={handleToggleSeeMore}
						style={{
							fontWeight: "bold",
							textDecoration: "underline",
							cursor: "pointer",
						}}>
						See More
					</span>
				</>
			);
		}

		return (
			<>
				{data.details.details}
				{seeMore && (
					<span
						onClick={handleToggleSeeMore}
						style={{
							fontWeight: "bold",
							textDecoration: "underline",
							cursor: "pointer",
						}}>
						See Less
					</span>
				)}
			</>
		);
	};
	return (
		<>
			{findFeature("Details")?.status === true && (
				<Container sx={{ pt: 3 }}>
					<Box
						sx={{ border: "3px solid", borderRadius: "15px", p: 1 }}
						className='borderColor'>
						<Typography
							className='textColor'
							sx={{ mb: 0.5 }}
							variant='h4'
							component='div'
							gutterBottom>
							{data?.headline?.aboutUs}
							<Divider className={`${data?.color?.hr}`} />
						</Typography>

						<Typography
							variant='body1'
							gutterBottom
							className='textColor'
							sx={{ whiteSpace: "pre-line" }}>
							{renderText()}
						</Typography>
					</Box>
				</Container>
			)}
		</>
	);
};

export default About;
