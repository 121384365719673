import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import Headline from "../Headline/Headline";
import { useAPI } from "../../../apiContext";

const ButtonsLink = ({ featureActiveBtn, findFeature }) => {
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			website: "",
			giveaway: "",
			skype: "",
			email: "",
			facebook: "",
			whatsapp: "",
			youtube: "",
			sms: "",
			call: "",
			twitter: "",
			instagram: "",
			pinterest: "",
			linkedin: "",
		},
	});

	const { setLoading, databyEmail, token } = useAPI();
	useEffect(() => {
		reset(databyEmail?.links);
	}, [databyEmail, reset]);

	const onSubmit = async ({
		skype,
		website,
		email,
		facebook,
		whatsapp,
		youtube,
		sms,
		call,
		twitter,
		instagram,
		pinterest,
		linkedin,
	}) => {
		setLoading(true);
		const data = {
			skype,
			website,
			email,
			facebook,
			whatsapp,
			youtube,
			sms,
			call,
			twitter,
			instagram,
			pinterest,
			linkedin,
			submitTime: new Date().toLocaleString(),
		};
		try {
			await axios
				.put(
					`${process.env.REACT_APP_API_PATH}/links/${databyEmail?._id}`,
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
				.then(function (response) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: "Successfully Updated",
						showConfirmButton: false,
						timer: 1500,
					});
				});
		} catch (error) {
			if (error.response.status) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.response.data,
				});
			} else {
				console.log(error);
			}
		}
	};

	return (
		<Box
			sx={{
				my: 2,
				position: "relative",
				border:
					findFeature("Buttons")?.status !== true && "3px solid red !important",
				backgroundColor:
					findFeature("Buttons")?.status !== true && "#ff000038 !important",
			}}>
			<Headline type={"buttons"} />

			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={2}>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='website'
							label='Website'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("website")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='skype'
							label='Skype'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("skype")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='Facebook'
							label='Facebook'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("facebook")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='Email'
							label='Email'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='WhatsApp'
							label='WhatsApp'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("whatsapp")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='Youtube'
							label='Youtube'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("youtube")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='CAll'
							label='CALL'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("call")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='SMS'
							label='SMS'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("sms")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='Twitter'
							label='Twitter'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("twitter")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='Instagram'
							label='Instagram'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("instagram")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='pinterest'
							label='Pinterest'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("pinterest")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							size='small'
							name='linkedin'
							label='LinkedIn'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("linkedin")}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								px: 3,
								fontWeight: "bold",
								borderRadius: "5px",
								border: "1px solid",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default ButtonsLink;
