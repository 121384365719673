import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Avatar, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import auth from "../../Firebase/firebase.config";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import GroupSharpIcon from "@mui/icons-material/GroupSharp";
import FaceSharpIcon from "@mui/icons-material/FaceSharp";
import ShareIcon from "@mui/icons-material/Share";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LockIcon from "@mui/icons-material/Lock";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import InventoryIcon from "@mui/icons-material/Inventory";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { useAPI } from "../../apiContext";
import HtmlTooltip from "./HtmlTooltip";
import { reactLocalStorage } from "reactjs-localstorage";
import PaymentIcon from "@mui/icons-material/Payment";
import axios from "axios";
import Swal from "sweetalert2";
import GavelIcon from "@mui/icons-material/Gavel";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { Helmet } from "react-helmet";

const drawerWidth = 240;

function Dashboard(props) {
	const navigate = useNavigate();
	const {
		databyEmail: data,
		toltips,
		setToltips,
		token,
		findFeature,
	} = useAPI();
	/* 	// redirect to login if not logged in
	React.useEffect(() => {
		if (!data?.email) {
			navigate("/login");
		}
	}, [data, navigate]); */

	const [paymentInfo, setPaymentInfo] = React.useState();
	React.useEffect(() => {
		if (data?.url) {
			const fetchData = async () => {
				try {
					const paymentInfos = await axios.get(
						`${process.env.REACT_APP_API_PATH}/paymentsByUser/${data?.url}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);

					setPaymentInfo(paymentInfos?.data);
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			};

			if (token) {
				fetchData();
			}
		}
	}, [data, token]);

	const location = useLocation();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	// eslint-disable-next-line no-lone-blocks
	{
		document.documentElement.style.setProperty(
			"--color1st",
			`${data?.color?.color1st}`,
		);
	}
	const drawer = (
		<Box className='dashboard'>
			<Typography
				sx={{
					py: 1.5,
					color: "white",
					width: "100%",
					fontWeight: "bold",
					fontSize: "22px !important",
				}}>
				DBC
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					py: 1,
				}}>
				<Avatar
					sx={{ border: "2px solid white", mb: 0.7 }}
					alt=''
					src={data?.profile?.imageLink2}
				/>
				<Typography sx={{ color: "white", fontSize: "17px !important" }}>
					{data?.headline?.title}
				</Typography>
				<Typography sx={{ color: "white" }}>{data?.role}</Typography>
			</Box>
			{findFeature("Profile") && data?.status === "Active" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to=''>
					<ListItem
						button
						className={location?.pathname === "/dashboard" && "activeButton"}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<FaceSharpIcon />
						</ListItemIcon>
						<ListItemText primary={"Profile"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='ChatBot'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/ChatBot" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PsychologyIcon />
							</ListItemIcon>
							<ListItemText primary={"ChatBot"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allusers'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/allusers" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<GroupSharpIcon />
							</ListItemIcon>
							<ListItemText primary={"All Users (Admin)"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='Terms'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/Terms" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<GavelIcon />
							</ListItemIcon>
							<ListItemText primary={"Terms (Admin)"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='AllSupports'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/AllSupports" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ContactSupportIcon />
							</ListItemIcon>
							<ListItemText primary={"All Supports (Admin)"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='ManagePackage'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/ManagePackage" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<InventoryIcon />
							</ListItemIcon>
							<ListItemText primary={"Manage Package (Admin)"} />
						</ListItem>
					</Link>
				</>
			)}

			{data?.status === "Active" ? (
				<>
					{" "}
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
							pointerEvents: data?.status === "Inactive" && "none",
						}}
						to='Payment'>
						<ListItem
							sx={{
								backgroundColor:
									data?.status === "Inactive" && "gray !important",
							}}
							button
							className={
								location?.pathname === "/dashboard/Payment" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PaymentIcon />
							</ListItemIcon>
							<ListItemText primary={"Payment Manage"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
							pointerEvents: data?.status === "Inactive" && "none",
						}}
						to='Refferels'>
						<ListItem
							sx={{
								backgroundColor:
									data?.status === "Inactive" && "gray !important",
							}}
							button
							className={
								location?.pathname === "/dashboard/Refferels" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<CoPresentIcon />
							</ListItemIcon>
							<ListItemText primary={"Referrals"} />
						</ListItem>
					</Link>
					{findFeature("Statistics") && (
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								pointerEvents: data?.status === "Inactive" && "none",
							}}
							to='Statistics'>
							<ListItem
								sx={{
									backgroundColor:
										data?.status === "Inactive" && "gray !important",
								}}
								button
								className={
									location?.pathname === "/dashboard/Statistics" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<SignalCellularAltIcon />
								</ListItemIcon>
								<ListItemText primary={"QR Scan Analytics"} />
							</ListItem>
						</Link>
					)}
					{findFeature("Before-Install-Submits") && (
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								pointerEvents: data?.status === "Inactive" && "none",
							}}
							to='BeforeInstallSubmits'>
							<ListItem
								sx={{
									backgroundColor:
										data?.status === "Inactive" && "gray !important",
								}}
								button
								className={
									location?.pathname === "/dashboard/BeforeInstallSubmits" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<GroupSharpIcon />
								</ListItemIcon>
								<ListItemText primary={"Before Install Submits"} />
							</ListItem>
						</Link>
					)}
					{findFeature("Custom-Share-Message") && (
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								pointerEvents: data?.status === "Inactive" && "none",
							}}
							to='ShareManage'>
							<ListItem
								sx={{
									backgroundColor:
										data?.status === "Inactive" && "gray !important",
								}}
								button
								className={
									location?.pathname === "/dashboard/ShareManage" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ShareIcon />
								</ListItemIcon>
								<ListItemText primary={"Share Manage"} />
							</ListItem>
						</Link>
					)}
					{findFeature("Gallery") && (
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								pointerEvents: data?.status === "Inactive" && "none",
							}}
							to='galleryphoto'>
							<ListItem
								sx={{
									backgroundColor:
										data?.status === "Inactive" && "gray !important",
								}}
								button
								className={
									location?.pathname === "/dashboard/galleryphoto" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ViewCarouselIcon />
								</ListItemIcon>
								<ListItemText primary={"Gallery Photos"} />
							</ListItem>
						</Link>
					)}
					{findFeature("Videos") && (
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								pointerEvents: data?.status === "Inactive" && "none",
							}}
							to='managevideo'>
							<ListItem
								sx={{
									backgroundColor:
										data?.status === "Inactive" && "gray !important",
								}}
								button
								className={
									location?.pathname === "/dashboard/managevideo" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<OndemandVideoIcon />
								</ListItemIcon>
								<ListItemText primary={"Gallery Videos"} />
							</ListItem>
						</Link>
					)}
					{findFeature("Color") && (
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								pointerEvents: data?.status === "Inactive" && "none",
							}}
							to='colorchange'>
							<ListItem
								sx={{
									backgroundColor:
										data?.status === "Inactive" && "gray !important",
								}}
								button
								className={
									location?.pathname === "/dashboard/colorchange" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ColorLensIcon />
								</ListItemIcon>
								<ListItemText primary={"Color & Border"} />
							</ListItem>
						</Link>
					)}
					{findFeature("Reviews") && (
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								pointerEvents: data?.status === "Inactive" && "none",
							}}
							to='allreviews'>
							<ListItem
								sx={{
									backgroundColor:
										data?.status === "Inactive" && "gray !important",
								}}
								button
								className={
									location?.pathname === "/dashboard/allreviews" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ReviewsIcon />
								</ListItemIcon>
								<ListItemText primary={"All Reviews"} />
							</ListItem>
						</Link>
					)}
					{findFeature("Mails") && (
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								pointerEvents: data?.status === "Inactive" && "none",
							}}
							to='allemails'>
							<ListItem
								sx={{
									backgroundColor:
										data?.status === "Inactive" && "gray !important",
								}}
								button
								className={
									location?.pathname === "/dashboard/allemails" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ContactMailIcon />
								</ListItemIcon>
								<ListItemText primary={"All Mails"} />
							</ListItem>
						</Link>
					)}
				</>
			) : (
				<Box>
					<Box
						className='textColor'
						sx={{
							height: "300px !important",
							backgroundColor: "white",
							width: "90%",
							borderRadius: "10px",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							margin: "auto",
							px: 1,
						}}>
						<Box
							className='borderColor'
							sx={{
								border: "2px solid",
								pl: 1,
								pr: 2,
								py: 0.7,
								borderRadius: "10px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								cursor: "not-allowed",
							}}>
							<LockIcon className='textColor' /> Locked
						</Box>
						<button
							onClick={() => {
								navigate("/dashboard/payment");
							}}
							className='bgColor borderColor'
							style={{
								padding: "7px",
								color: "white",
								borderRadius: "5px",
								margin: "10px 0px",
								cursor: "pointer",
							}}
							size='small'>
							{paymentInfo?.subscriptionStatus === "canceled"
								? "Manage Subscription"
								: "Subscribe Now"}
						</button>
						<strong>
							{paymentInfo?.subscriptionStatus === "canceled"
								? "Your subscription has been canceled."
								: "Subscribe now to unlock the features. You will have 3 days free trial."}
						</strong>
					</Box>
				</Box>
			)}
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<Box sx={{ display: "flex" }} id='dashboard'>
			<Helmet>
				{data?.url && (
					<link
						rel='manifest'
						href={`${process.env.REACT_APP_API_PATH}/manifest/${data?.url}`}
					/>
				)}
			</Helmet>
			<>
				<CssBaseline />
				<AppBar
					position='fixed'
					className='bgColor'
					sx={{
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						padding: 0,
						ml: { sm: `${drawerWidth}px` },
					}}>
					<Toolbar>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: "none" } }}>
							<HtmlTooltip
								sx={{ display: { sm: "none" } }}
								open={toltips?.dashboardSmall}
								title={
									<Box>
										<Typography className='textColor'>
											Dashboard, here you can manage all the features of your
											card.
										</Typography>
										<Button
											sx={{ border: "1px solid", py: 0, mt: 1.5 }}
											onClick={() => {
												setToltips({
													...toltips,
													dashboardSmall: false,
													profileLink: true,
												});
												reactLocalStorage.setObject("toltips", {
													...toltips,
													dashboardSmall: false,
													profileLink: true,
												});
											}}>
											Ok
										</Button>
									</Box>
								}>
								<MenuIcon />
							</HtmlTooltip>
						</IconButton>
						<Box display='flex' sx={{ flexGrow: 1, alignItems: "center" }}>
							<DashboardIcon sx={{ mr: 1 }} />
							<Typography variant='h6'>Dashboard</Typography>
						</Box>
						<Button onClick={() => signOut(auth)} className='logout'>
							LogOut
						</Button>
					</Toolbar>
				</AppBar>
				<Box
					onClick={() => setMobileOpen(false)}
					component='nav'
					sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
					aria-label='mailbox folders'>
					<Drawer
						container={container}
						variant='temporary'
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true,
						}}
						className='dashboard'
						sx={{
							display: { xs: "block", sm: "none" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
							backgroundColor: "transparent",
						}}>
						{drawer}
					</Drawer>

					<Drawer
						variant='permanent'
						className='dashboard'
						sx={{
							display: { xs: "none", sm: "block" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
							backgroundColor: "transparent",
						}}
						open>
						<HtmlTooltip
							sx={{
								"& .MuiTooltip-arrow": {
									transform: "translate(-13px, 35px) !important",
									width: "1.71em !important",
									height: "3.71em !important",
								},
								display: { xs: "none", sm: "block" },
							}}
							open={toltips?.dashboardBig}
							placement='right'
							title={
								<Box>
									<Typography className='textColor'>
										Dashboard, here you can manage all the features of your
										card.
									</Typography>
									<Button
										sx={{ border: "1px solid", py: 0, mt: 1.5 }}
										onClick={() => {
											setToltips({
												...toltips,
												dashboardBig: false,
												profileLink: true,
											});
											reactLocalStorage.setObject("toltips", {
												...toltips,
												dashboardBig: false,
												profileLink: true,
											});
										}}>
										Ok
									</Button>
								</Box>
							}>
							{drawer}
						</HtmlTooltip>
					</Drawer>
				</Box>
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
					}}>
					<Toolbar />
					{data?.status === "Inactive" &&
						!location.pathname.includes("/payment") && (
							<Alert
								sx={{
									width: { md: "max-content", xs: "90%" },
									textAlign: "left",
									position: "fixed",
									top: { md: "13%", xs: "9%" },
									right: "50%",
									transform: "translate(50%,-13%)",
									zIndex: 9,
								}}
								action={
									<>
										<button
											onClick={() => {
												navigate("/dashboard/payment");
											}}
											style={{
												backgroundColor: "#F27471",
												padding: "7px",
												color: "white",
												borderRadius: "5px",
												cursor: "pointer !important",
											}}
											size='small'>
											{paymentInfo?.subscriptionStatus === "canceled"
												? "Manage Subscription"
												: "Subscribe Now"}
										</button>
									</>
								}
								severity='error'>
								<AlertTitle>Warning</AlertTitle>
								<strong>
									{paymentInfo?.subscriptionStatus === "canceled"
										? "Your subscription has been canceled."
										: "Subscribe now to unlock the features. You will have 3 days free trial."}
								</strong>
							</Alert>
						)}

					<Outlet></Outlet>
				</Box>
			</>
		</Box>
	);
}

Dashboard.propTypes = {
	window: PropTypes.func,
};

export default Dashboard;
