import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import { useAPI } from "../../../apiContext";
import Headline from "../Headline/Headline";

const Details = ({ type, featureActiveBtn, findFeature }) => {
	const form = useRef();
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			details: "",
			shortDetails: "",
			quickActionLink: "",
			quickActionBtn: "",
			quickActionBtnType: "",
		},
	});

	const { setLoading, databyEmail, token } = useAPI();
	useEffect(() => {
		reset(databyEmail?.details);
	}, [databyEmail, reset]);

	const onSubmit = async ({
		details,
		shortDetails,
		quickActionLink,
		quickActionBtn,
	}) => {
		setLoading(true);
		const data = {
			details,
			shortDetails,
			quickActionLink,
			quickActionBtn,
			quickActionBtnType: types || databyEmail?.details?.quickActionBtnType,
			submitTime: new Date().toLocaleString(),
		};
		try {
			await axios
				.put(
					`${process.env.REACT_APP_API_PATH}/details/${databyEmail?._id}`,
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
				.then(function (response) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: "Your details updated Successfully",
						showConfirmButton: false,
						timer: 1500,
					});
				});
		} catch (error) {
			if (error.response.status) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.response.data,
				});
			} else {
				console.log(error);
			}
		}
	};
	const [types, setTypes] = React.useState("");
	return (
		<Box
			sx={{
				my: 2,
				position: "relative",
				border:
					type === "about" &&
					findFeature("Details")?.status !== true &&
					"3px solid red !important",
				backgroundColor:
					type === "about" &&
					findFeature("Details")?.status !== true &&
					"#ff000038 !important",
			}}>
			{type === "about" && <Headline type={"about"} />}

			<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={2}>
					{type === "banner" && (
						<>
							<Grid item xs={12}>
								<TextField
									multiline
									rows={4}
									required
									size='small'
									name='Short Details'
									label='Short Details'
									fullWidth
									InputLabelProps={{
										shrink: true,
									}}
									{...register("shortDetails", { required: true })}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									size='small'
									name='quickActionBtn'
									label='Quick Action Btn Text'
									fullWidth
									InputLabelProps={{
										shrink: true,
									}}
									{...register("quickActionBtn", { required: true })}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth size='small'>
									<InputLabel id='demo-simple-select-label'>
										Select Button Type
									</InputLabel>
									<Select
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										label='Select Button Type'
										sx={{ textAlign: "left !important" }}
										value={
											types || databyEmail?.details?.quickActionBtnType || ""
										}
										onChange={(e) => setTypes(e.target.value)}>
										<MenuItem value={"Link"}>Link</MenuItem>
										<MenuItem value={"Phone"}>Phone</MenuItem>
										<MenuItem value={"Email"}>Email</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									size='small'
									name='quickActionLink'
									label='Quick Action Btn Link'
									fullWidth
									InputLabelProps={{
										shrink: true,
									}}
									{...register("quickActionLink", { required: true })}
								/>
							</Grid>
						</>
					)}
					{type === "about" && (
						<Grid item xs={12}>
							<TextField
								required
								size='small'
								name='Details'
								label='Details'
								fullWidth
								multiline
								rows={7}
								InputLabelProps={{
									shrink: true,
								}}
								{...register("details", { required: true })}
							/>
						</Grid>
					)}

					<Grid item xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								px: 3,
								fontWeight: "bold",
								borderRadius: "5px",
								border: "1px solid",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default Details;
