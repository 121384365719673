import { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const QrScanRedirect = () => {
	const { user } = useParams();
	const [location, setLocation] = useState(null);
	useEffect(() => {
		axios
			.get(
				`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_ipgeolocation}`,
			)
			.then((res) => {
				setLocation(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const setVisitedToday = (userId) => {
		const now = new Date();
		const expiration = new Date(
			now.getFullYear(),
			now.getMonth(),
			now.getDate() + 1,
		);
		document.cookie = `visitedToday-${userId}=true; expires=${expiration.toUTCString()}; path=/`;
	};

	useEffect(() => {
		const visitedToday = document.cookie.includes(`visitedToday-${user}=true`);
		axios
			.get(`${process.env.REACT_APP_API_PATH}/checkUserName/${user}`)
			.then((res) => {
				if (
					!visitedToday &&
					location &&
					res.data.find === true &&
					res.data.checkStatistics === true
				) {
					// Increment the count for the user and save it to the API
					axios
						.put(`${process.env.REACT_APP_API_PATH}/Qr_Scan/${user}`, {
							date: new Date().toLocaleString(),
							countryFlag: location?.country_flag,
							country: location?.country_name,
							city: location?.city,
							region: location?.state_prov,
							zip: location?.zipcode,
							latitude: location?.latitude,
							longitude: location?.longitude,
							ip: location?.ip,
							organization: location?.organization,
							isp: location?.isp,
							id: `event${Math.floor(100000 + Math.random() * 900000)}`,
						})
						.then((res) => {
							// Redirect the user
							window.location.href = `/@${user}`;
						})
						.then((error) => console.log(error));
					// Set the visitedToday flag for the user
					setVisitedToday(user);
				} else if (visitedToday) {
					// Redirect the user
					window.location.href = `/@${user}`;
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, [location, user]);
	return (
		<div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					minHeight: "100vh",
				}}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h5'
					component='div'>
					You are being redirected to {user} card...
				</Typography>
			</div>
		</div>
	);
};

export default QrScanRedirect;
