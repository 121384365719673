import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { Backdrop, CardMedia, CircularProgress, Grid } from "@mui/material";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import auth from "../../Firebase/firebase.config";
import Swal from "sweetalert2";
import ResetPass from "../ResetPass/ResetPass";

export default function Login() {
	const navigate = useNavigate();
	const [signInWithEmailAndPassword, user, loading, error] =
		useSignInWithEmailAndPassword(auth);
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [loggedUser, setLoggedUser] = React.useState(null);

	if (user || loggedUser) {
		navigate("/dashboard");
	}
	React.useEffect(() => {
		if (error) {
			Swal.fire({
				icon: "error",
				title: `${
					error?.message === "Firebase: Error (auth/wrong-password)."
						? "Wrong Password, Try again or Reset your password."
						: error?.message
				}`,
				showConfirmButton: true,
			});
		}
	}, [error, user]);
	const [resetpass, setResetpass] = React.useState(false);

	return (
		<Container>
			<Container
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "100vh",
					width: "100vw",
				}}>
				<Grid
					container
					spacing={2}
					className='borderColor'
					sx={{
						alignItems: "stretch",
						justifyContent: "center",
						border: "1px solid",
						borderRadius: "10px",
					}}>
					<Grid item xs={12} md={6} sx={{ borderRadius: "10px 0 0 10px" }}>
						<Box
							className='textColor'
							sx={{
								p: 3,
							}}>
							<CardMedia
								image='/digicard.jpg'
								alt='loading logo'
								sx={{
									width: 170,
									height: 110,
									mx: "auto",
									my: 2,
								}}
							/>
							<Typography variant='h5' mb={2}>
								Connect, Impress, and Grow with DigiCardsPro
							</Typography>
							<Typography mb={2}>
								Effortlessly showcase your professional identity and make
								meaningful connections with our Digital Business Card. With our
								innovative platform, you can create a sleek and dynamic digital
								representation of your business profile, complete with contact
								details, social media links, and a personalized touch. Stand out
								from the crowd, leave a lasting impression, and easily share
								your information with clients, colleagues, and prospects. Say
								goodbye to paper business cards and embrace the convenience and
								versatility of our Digital Business Card solution, empowering
								you to network smarter and make your mark in the digital age.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} sx={{ m: "auto" }}>
						{!resetpass ? (
							<Box sx={{ p: 3 }}>
								<Typography
									variant='h5'
									mb={2}
									className='textColor'
									fontWeight='bold'>
									Log In
								</Typography>
								<Grid container spacing={1.3}>
									<Grid item xs={12}>
										<TextField
											required
											fullWidth
											id='email'
											label='Email Address'
											name='email'
											autoComplete='off'
											autoFocus
											onChange={(e) => setEmail(e.target.value)}
										/>{" "}
									</Grid>
									<Grid item xs={12}>
										<Typography
											variant='body2'
											className='textColor'
											sx={{ textDecoration: "underline", cursor: "pointer" }}
											onClick={() => window.location.replace("/createAccount")}>
											Don't have an account ? Sign Up Now !
										</Typography>{" "}
									</Grid>
									<Grid item xs={12}>
										<TextField
											required
											fullWidth
											name='password'
											label='Password'
											type='password'
											id='password'
											autoComplete='new-password'
											onChange={(e) => setPassword(e.target.value)}
										/>{" "}
									</Grid>
									<Grid item xs={12}>
										<Typography
											variant='body2'
											className='textColor'
											sx={{ textDecoration: "underline", cursor: "pointer" }}
											onClick={() => setResetpass(true)}>
											Forgot your password ? Reset Now !
										</Typography>{" "}
									</Grid>

									<Grid item xs={12}>
										<Button
											className='bgColor'
											sx={{ px: 2 }}
											onClick={() =>
												signInWithEmailAndPassword(email, password)
											}>
											Log In
										</Button>
									</Grid>
								</Grid>
							</Box>
						) : (
							<ResetPass
								setResetpass={setResetpass}
								setLoggedUser={setLoggedUser}
							/>
						)}
					</Grid>
				</Grid>
			</Container>

			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
}
